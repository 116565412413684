// register user api service

// Function to register a user by making a POST request to the '/api/user/register' endpoint
const registerUser = async (formData) => {
    try {
        // Make a POST request to the '/api/user/register' endpoint with the provided form data
        const response = await fetch('/api/user/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        // Check if the response is OK (status code 2xx)
        if (response.ok) {
            // Parse the response body as JSON
            const data = await response.json();
            // Return the parsed JSON data
            return {success: true,  message: 'success'};
        } else {
            // Throw an error if the response is not OK
            const data = await response.json();
            return {success: false, message: data.message}
        }
    } catch (error) {
        // Throw an error if there's a network error or any other error during the request
        throw new Error(`Network error: ${error.message}`);
    }
};

// Export the registerUser function to make it available for use in other modules
export { registerUser };
