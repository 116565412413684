import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TextField, Container, Typography, Alert, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { verifyUserApi } from '../services/verifyUserApi';
import { useAuth } from '../Context/AuthContext';
import { logoutUser } from '../services/logoutUser';


export const UserVerify = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const navigate = useNavigate();
  const { isLoggedIn, logout, login } = useAuth();

  useEffect(() => {
    // Check if the token is present in the URL
    if (!token) {
      // If not, redirect to the homepage
      navigate('/'); // Adjust this to your homepage route if it's different
    } 

    if (isLoggedIn) {
      console.log("YELLLLLLOOOO")
      logoutUser(logout)
    } else {
      console.log("awwww")
    }

    // logout()
  }, [navigate, token, isLoggedIn]); // Depend on navigate and token to rerun this effect if they change


  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleVerifyClick = async () => {

    try {
      if (password !== confirmPassword) {
        setErrorMessage('Passwords do not match. Please try again.');
      } else {
        setErrorMessage('');
        console.log('Verification logic here');
        const verifyResponse = await verifyUserApi(token, password)
        if (verifyResponse.ok) {
          login()
          navigate('/home')
        } else {
          console.log("Need to handle this")
        }
      }
      
    } catch (error) {
      console.error(error)
      throw error;
    }
  
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Verify Your Account
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm Password"
        type={showConfirmPassword ? 'text' : 'password'}
        id="confirm-password"
        autoComplete="new-password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 2 }}
        onClick={handleVerifyClick}
      >
        Verify
      </Button>
    </Container>
  );
};


