import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { EmailInput, OrganizationInput, NameInput, PasswordInput } from './FormInput';
import { Link } from 'react-router-dom';

const RegisterForm = ({ formState, handleChange, handleSubmit, handleClose }) => {
  return (
    <Box className="registration-box">
      <Typography
        id="login-modal-title"
        component="h2"
        sx={{
          fontFamily: 'Manrope, sans-serif',
          fontSize: 25
        }}
      >
        Sign up to gain access
      </Typography>
      <form onSubmit={handleSubmit}>
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>Organisation</Typography>
        <OrganizationInput
          placeholder='My Medical Centre'
          name="organisationName"
          value={formState.organisationName}
          onChange={handleChange}
        />
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>First Name</Typography>
        <NameInput
          placeholder="First Name"
          name="firstName"
          value={formState.firstName}
          onChange={handleChange}
        />
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>Last Name</Typography>
        <NameInput
          placeholder="Last Name"
          name="lastName"
          value={formState.lastName}
          onChange={handleChange}
        />
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>Email address</Typography>
        <EmailInput
          placeholder="Email"
          name="email"
          value={formState.email}
          onChange={handleChange}
        />
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>Password</Typography>
        <PasswordInput
          placeholder="Password"
          name="password"
          value={formState.password}
          onChange={handleChange}
        />
        <Box mt={1}>
          <Button type="submit" variant="contained" color="primary" fullWidth  sx ={{
          backgroundColor:"#56C596",
          borderColor:'#56C596 !important',
          
          textTransform: 'none' // Keeps text as provided
          }}>
            Register
          </Button>
          {/* <Button onClick={handleClose} sx={{ marginLeft: 1 }}>
            Close
          </Button> */}
        </Box>

        {/* <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
      <Divider sx={{ height: '2px', width: '40px', backgroundColor: '#BBE8D5' }} />
      <Typography variant="body1" color="#BBE8D5" sx={{ mx: 2 }}>or</Typography>
      <Divider sx={{ height: '2px', width: '40px', backgroundColor: '#BBE8D5' }} />
    </Box> */}

    {/* <Typography variant="body1" align="center" style={{ paddingTop: '20px', fontFamily: 'Manrope, sans-serif' }}>
  Already an account?{' '}
  <Link to="/send-reset-password" style={{ color: '#56C596', fontFamily: 'Manrope, sans-serif', fontSize: '16px', textDecoration: 'none', marginLeft: '4px' }}>
    Login
  </Link>
</Typography> */}
      </form>
    </Box>
  );
};

export default RegisterForm;
