import React from 'react';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';

const ConcessionEligibilityRecordedSection = ({ setConcessionRecorded }) => {
    // Handler function for updating the concession recorded state based on user selection
    const handleSetConcessionRecorded = (event) => {
        // Convert the value to a boolean
        let booleanVal = event.target.value === 'true' ? true : false;
        // Call the provided function to update the concession recorded state
        setConcessionRecorded(booleanVal);
    };
    
    return (
        // Form control for selecting concession eligibility recorded status
        <FormControl component="fieldset" sx={{ textAlign: 'center', mt: 3 }}>
            {/* Form label indicating the purpose of the selection */}
            <FormLabel component="legend" sx={{ fontSize: '1.8rem', mb: 1 }}> <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>Is the patient's concession eligibility recorded?</Typography> </FormLabel>
            {/* Radio group for selecting true or false */}
            <RadioGroup
                row
                aria-label="concession-recorded"
                name="row-radio-buttons-group"
              
                onChange={handleSetConcessionRecorded} // Call the handler function on change
                sx={{ justifyContent: 'center' }}
            >
                {/* Radio button for true */}
                <FormControlLabel
                    value={true}
                    control={<Radio sx={{color: '#56C496'}}/>}
                    label="Yes"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.5rem' } }}
                />
                {/* Radio button for false */}
                <FormControlLabel
                    value={false}
                    control={<Radio sx={{color: '#56C496'}}/>}
                    label="No"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.5rem' } }}
                />
            </RadioGroup>
        </FormControl>
    );
}

export default ConcessionEligibilityRecordedSection;
