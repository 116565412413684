import { Modal, Box, TextField, Typography } from "@mui/material";
import GeneralButton from "../General/Button";
import { useState, useEffect } from "react";

export const AddBillingItem = ({
  openSpecialBillingModal,
  newSpecialBillingItem,
  handleCloseSpecialBillingModal,
  handleSaveSpecialBillingValues,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  // Initialize state with initial values from props
  const [values, setValues] = useState(newSpecialBillingItem);
  const [errors, setErrors] = useState({});

  // Update the internal state when initialValues prop changes
  useEffect(() => {
    setValues(newSpecialBillingItem);
  }, [newSpecialBillingItem]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  // Validate form fields
  const validate = () => {
    let tempErrors = {};
    if (!values.billingCaseDescription) {
      tempErrors.billingCaseDescription = 'Special Case is required';
    }
    if (!values.mbsRebate) {
      tempErrors.mbsRebate = 'MBS Rebate is required';
    }
    if (!values.privateFee) {
      tempErrors.privateFee = 'Private Fee is required';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      handleSaveSpecialBillingValues(values);
      handleCloseSpecialBillingModal();
    }
  };

  return (
    <Modal
      open={openSpecialBillingModal}
      onClose={handleCloseSpecialBillingModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {`Add Special Case for ${values.billingNumber}`}
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            fullWidth
            margin="normal"
            label="Special Case"
            name="billingCaseDescription"
            value={values.billingCaseDescription}
            onChange={handleChange}
            required
            error={!!errors.billingCaseDescription}
            helperText={errors.billingCaseDescription}
          />
          <TextField
            fullWidth
            margin="normal"
            label="MBS Rebate"
            type="number"
            name="mbsRebate"
            value={values.mbsRebate}
            onChange={handleChange}
            required
            error={!!errors.mbsRebate}
            helperText={errors.mbsRebate}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Private Fee"
            type="number"
            name="privateFee"
            value={values.privateFee}
            onChange={handleChange}
            required
            error={!!errors.privateFee}
            helperText={errors.privateFee}
          />
          <GeneralButton
            text={"Add"}
            onClick={handleSave}
            sx={{
              backgroundColor: "#56C596",
              borderColor: "#56C596 !important",
              textTransform: "none", // Keeps text as provided
              width: "4.50vw",
              marginRight: '10px'
            }}
          />
          <GeneralButton
            text={"Cancel"}
            onClick={handleCloseSpecialBillingModal}
            variant={"outlined"} 
            sx={{
                color: '#56C596', // Text and border color
                borderColor: '#56C596 !important', // Explicit border color
                textTransform: 'none', // Keeps text as provided
                backgroundColor: 'transparent',
                boxShadow: 'none',
                width: '4.50vw',
                '&:hover': {
                  backgroundColor: '#56C596', // Fills the button on hover
                  color: 'white', // Changes text color to white on hover
                  borderColor: '#56C596', // Ensures border color remains consistent on hover
                }
              }} 
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddBillingItem;
