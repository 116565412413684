// passwordResetService.js

/**
 * Sends a password reset request to the server.
 * 
 * @param {string} token Password reset token.
 * @param {string} newPassword The new password.
 * @returns {Promise<{success: boolean, message: string}>} The result of the password reset attempt.
 */
 export const resetPassword = async (token, newPassword) => {
    try {
      const response = await fetch('/api/user/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      });
  
      const data = await response.json(); // Convert response to JSON
  
      if (!response.ok) {
        // If response is not ok, throw an error with message from the response
        throw new Error(data.message || 'Failed to reset password.');
      }
  
      // Return success data
      return {
        success: true,
        message: data.message || 'Password successfully reset.',
      };
    } catch (error) {
      console.error('Error sending password reset request:', error);
      // Return error information
      return {
        success: false,
        message: error.message || 'Failed to send password reset request.',
      };
    }
  };
  