export const deleteSpecialItem = async (itemInfo) => {
    try {
      const response = await fetch(`/api/billing-info/delete-special-billing-item/${itemInfo.billingNumberParent}/${itemInfo.itemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // If you're using token-based authentication, uncomment and replace <YOUR_TOKEN> with the actual token
          // 'Authorization': `Bearer <YOUR_TOKEN>`
        },
        // No body is needed for a DELETE request
      });
  
      if (response.ok) {
        // If the response is OK, handle the success (e.g., update the UI or alert the user)
        console.log('Organisation  deleted successfully');
        return true; 
        // You might want to refresh the list of admin users or navigate away from the current view
      } else {
        // If the server responded with an error status, handle it (e.g., show an error message)
        console.error('Failed to delete organisation:', response.statusText);
        // You can also parse and use the response body for more detailed error handling
        // const errorData = await response.json();
        // console.error(errorData.message);
        return false; 
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      console.error('Error deleting admin user:', error);
    }
  };