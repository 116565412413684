import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: "#eaebec", // Your global background color
    }
  },
  shape: {
    borderRadius: 10, // You can adjust the border radius here
  },
  typography: {
    fontFamily: `"Manrope", "sans-serif"`, 
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: { // Fix: Use 'paper' instead of 'rounded'
          borderRadius: '16px', // Adjust this value for modal border radius
        },
      },
    },
    MuiTypography: {
      fontFamily: `"Manrope", "sans-serif"`,
      styleOverrides: {
        h3: {
          color: '#2e8b57', // Set the color for h3 tags
        },
        h1: {
            color: '#2e8b57', // Set the color for h3 tags
          },
          h2: {
            color: '#2e8b57', // Set the color for h3 tags
          },
          h4: {
            color: '#2e8b57', // Set the color for h3 tags
          },
          h6: {
            color: 'black', // Set the color for h3 tags
          },
      
      },
    },
    MuiButton: {
      fontFamily: `"Manrope", "sans-serif"`,
        styleOverrides: {
          root: {
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#2e8b57', // Button background color
            color: '#fff', // Button text color
            borderRadius: '10px', // Button border radius
            '&:hover': {
              backgroundColor: '#26734d', // Button hover state background color
            },
            '&:disabled': {
              backgroundColor: '#a0a0a0', // Optional: Button disabled state background color
            },
          },
        },
      },
    MuiFormLabel: {
      fontFamily: `"Manrope", "sans-serif"`,
        styleOverrides: {
          root: {
            fontSize: '1rem', // Example font size
            color: '#2e8b57', // Example color
            marginBottom: '8px', // Example bottom margin
            // Add any other styles you want to apply to FormLabel
          
          },
        },
        MuiRadio: {
          styleOverrides: {
          root: {
            color: '#56C496'
          },
        },
        }
      },
    },
  });

export default theme;
