import GeneralButton from "../General/Button"
import { Box, Typography } from "@mui/material"
import React from 'react';
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";


export const SelectMixedBillingType = ({ setShowNext, setCalculationType }) => {

    const handleSetShowNext = (event) => {
        setCalculationType(event.target.value);
        setShowNext(true);
    };

    return (
        <FormControl component="fieldset" sx={{ textAlign: 'center' }}>
        {/* Label for the radio group */}
        <FormLabel component="legend" sx={{ fontSize: '35px', mb: 1, fontFamily: 'Manrope, sans-serif', color: 'black', '&.Mui-focused': {
            color: 'black' // Keeps the color black even when focused
        } }}>How Would You Like To Calculate Your Fee?</FormLabel>
        
        {/* Radio group for selecting billing type */}
        <RadioGroup aria-label="billing" name="radio-buttons-group" onChange={handleSetShowNext} sx={{ top: '2.5vh' }}>
            {/* Radio button for Bulk Billing */}
            <FormControlLabel value="custom-gap" control={<Radio sx={{ color: '#56C496' }} />} label="Custom Gap" sx={{ '& .MuiFormControlLabel-label': { fontSize: '20px' } }} />
            {/* Radio button for Mixed Billing */}
            <FormControlLabel value="split-across" control={<Radio sx={{ color: '#56C496' }} />} label="Split Billing" sx={{ '& .MuiFormControlLabel-label': { fontSize: '20px' } }} />
            {/* Radio button for Billing Notation */}
            <FormControlLabel value="custom-private" control={<Radio sx={{ color: '#56C496' }} />} label="Custom Private Fee" sx={{ '& .MuiFormControlLabel-label': { fontSize: '20px' } }} />
        </RadioGroup>
    </FormControl>
    );
};
