import React, { useState } from 'react';
import {
    Typography,
    Box,
    IconButton,
    Collapse,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GeneralButton from '../General/Button';

// Total Cost - Medicare Rebate - Out of Pocket
const CustomGapResults = ({ calculationResults, handleReset }) => {
    const [expandedItems, setExpandedItems] = useState({});
    const [customInstructionExpanded, setCustomInstructionExpanded] = useState(false);

    const handleExpandClick = (index) => {
        setExpandedItems((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const handleCustomInstructionExpandClick = () => {
        setCustomInstructionExpanded(!customInstructionExpanded);
    };

    const handleCopy = (text) => {
        const formattedText = text.replace(/\n/g, ' ').replace(/-/g, '');
        navigator.clipboard.writeText(formattedText);
    };

    const formatCurrency = (value) => `$${Number(value).toFixed(2)}`;

    const totalPrivateFee = calculationResults.reduce((acc, value) => acc + value.adjustedPrivateFee, 0);
    const totalMedicareRebate = calculationResults.reduce((acc, value) => acc + value.mbsRebate, 0);

    const generateBillingInstruction = () => {
        const itemsDetails = calculationResults.map(value => 
            `\n - Item ${value.billingNumber} with a value of ${formatCurrency(value.adjustedPrivateFee)}`
        ).join(', ');

        return `Please charge the patient ${formatCurrency(totalPrivateFee)}.\n
Please let them know that the MBS rebate is ${formatCurrency(totalMedicareRebate)}.\n
Please let them know that the total out of pocket cost to them (gap) is ${formatCurrency(totalPrivateFee - totalMedicareRebate)}.\n
Please charge the following private fees for each item: ${itemsDetails}.`;
    };

    const billingInstruction = generateBillingInstruction();

    return (
        <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
                Results for Custom Gap
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', backgroundColor: 'white', m: 2, borderRadius: '10px', textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>Total Up Front Cost: {formatCurrency(totalPrivateFee)}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>Total MBS Rebate: {formatCurrency(totalMedicareRebate)}</Typography>
                   
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>
                       Total Patient out of pocket (Gap): {formatCurrency(totalPrivateFee - totalMedicareRebate)}
                    </Typography>
                </Box>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {calculationResults.map((value, index) => (
                        <li key={index} style={{ margin: '5px 0' }}>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="body1" sx={{ mb: 0.5 }}>
                                    Calculation Breakdown for Item Number: {value.billingNumber}{' '}
                                    <IconButton
                                        onClick={() => handleExpandClick(index)}
                                        aria-expanded={expandedItems[index]}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Collapse in={expandedItems[index]} timeout="auto" unmountOnExit>
                                <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', backgroundColor: 'white', mb: 6, borderRadius: '10px', textAlign: 'left' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>
                                        Adjusted Private Fee: {formatCurrency(value.adjustedPrivateFee)}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>
                                        Adjusted Gap: {formatCurrency(value.adjustedGap)}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>
                                        Gap for Patient ({formatCurrency(value.adjustedGap)}) + MBS Rebate ({formatCurrency(value.mbsRebate)}) = {formatCurrency(value.mbsRebate + value.adjustedGap)}
                                    </Typography>
                                </Box>
                            </Collapse>
                        </li>
                    ))}
                </ul>
                <Box sx={{ textAlign: 'left', mt: 2 }}>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                        Custom Billing Instruction{' '}
                        <IconButton
                            onClick={handleCustomInstructionExpandClick}
                            aria-expanded={customInstructionExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Collapse in={customInstructionExpanded} timeout="auto" unmountOnExit>
                    <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', backgroundColor: 'white', mb: 6, borderRadius: '10px', textAlign: 'left', p: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', mb: 1, whiteSpace: 'pre-line' }}>
                            {billingInstruction}
                            <Tooltip title="Copy Billing Instruction">
                                <IconButton onClick={() => handleCopy(billingInstruction)} aria-label="copy to clipboard">
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <GeneralButton onClick={handleReset} text={'Finish'} sx={{
                backgroundColor: "#56C596",
                borderColor: '#56C596 !important',
                textTransform: 'none', // Keeps text as provided
                width: '6.50vw',
            }}></GeneralButton>
        </Box>
    );
};

export default CustomGapResults;
