export const verifyUserApi = async (token, password) => {

    try {
        const response = await fetch(`/api/user/verify?token=${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password }) // Send the password along with the token
        })

        if (response.ok) {
            
            const data = await response.json();
            
            return data;
            
        } else {
            // Throw an error if the response is not OK
            throw new Error(`HTTP error: ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        // Throw an error if there's a network error or any other error during the request
        console.error(error)
        throw new Error(`Network error: ${error.message}`);
    }
}


