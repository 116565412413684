import React, {useEffect} from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Button, IconButton, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Main component to render concession items
const ConcessionItemsRenderComponent = ({ concessionValues, finishFunction, concessionBillingCombinationValues }) => {
    const notateProperty = 'notate';
    const propertyProperty = 'property'



    // Adding notate property to concessionValues based on concessionBillingCombinationValues
    for (const combo of concessionBillingCombinationValues) {
        
        for (const concessionVal of concessionValues) {
            if (concessionVal.billingNumber === Object.keys(combo)[0]) {
                // what is happening here is that when the if statement evaulates as true
                // the value of the outer loop item gets assigned to both notate/property for concessionVal 
                // because the array containing property and notation share the same key
                // this is not good code - need to fix.

                // adding both notation/property at once
                if (!concessionVal.hasOwnProperty(notateProperty)) {
                    concessionVal[notateProperty] = combo[concessionVal.billingNumber]; 
                    // concessionVal[propertyProperty] = combo[concessionVal.billingNumber]; 
                    continue
                }
            }
        }
    }

    // Handle the case where there is not notation/property
    for (const concessionVal of concessionValues) {
       if (!concessionVal.hasOwnProperty(notateProperty)) {
            concessionVal[notateProperty] = {'notation' : 'No notation found', 'property' : 'No property found'};
            // concessionVal[propertyProperty] = {'property' : 'No property found'};
        } 
    }

   console.log(concessionValues)

    // Function to handle copying text to clipboard
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => console.log('Copied to clipboard:', text))
            .catch(err => console.error('Failed to copy:', err));
    };

    return (
        <>
            {/* Title */}
            <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
                Item numbers and associated bulk
            </Typography>
            <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
                billing incentives
            </Typography>
            <br />
            {/* Mapping through concession values */}
            {concessionValues.map((billingItem, index) => (
                <div key={index}>
                    <Typography sx={{ width: '100%', textAlign: 'left', mb: 1, color: 'text.secondary' }}>
                        {billingItem.billingNumber}
                    </Typography>
                    <Card sx={{ marginBottom: 2, width: '20.42vw' }}>
                        <CardContent>
                            <List>
                                {/* Rendering associated items */}
                                {billingItem.associatedItems.map((associatedItem, subIndex) => (
                                    <React.Fragment key={`${index}-${subIndex}`}>
                                        <ListItem>
                                            <ListItemText primary={`Bulk Billing Incentive: ${associatedItem}`} />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                                {/* Rendering billing notation with copy functionality */}
                                <ListItem key={`notation-${index}`}>
                                    <ListItemText primary={
                                        <>
                                            <strong>Billing Notation:</strong> {billingItem.notate.notation}
                                            <IconButton size="small" onClick={() => handleCopy(billingItem.notate.notation)} sx={{ ml: 1 }}>
                                                <ContentCopyIcon fontSize="small" />
                                            </IconButton>
                                        </>
                                    } />
                                </ListItem>
                                <ListItem key={`property-${index}`}>
                                    <ListItemText primary={
                                        <>
                                            <strong>Billing Property:</strong> {billingItem.notate.property}
                                            <IconButton size="small" onClick={() => handleCopy(billingItem.notate.property)} sx={{ ml: 1 }}>
                                                <ContentCopyIcon fontSize="small" />
                                            </IconButton>
                                        </>
                                    } />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </div>
            ))}
            {/* Finish button */}
            <Button onClick={finishFunction} sx={{
                color: 'white', 
                borderColor: '#56C596 !important', 
                textTransform: 'none', 
                backgroundColor: '#56C596',
                boxShadow: 'none',
                width: '10.0vw', 
                height: '6.1vh', 
                '&:hover': {
                    backgroundColor: '#56C596', 
                    color: 'white', 
                    borderColor: '#56C596', 
                }
            }}>Finish</Button>
        </>
    );
};

export default ConcessionItemsRenderComponent;
