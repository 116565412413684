import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext'; // Adjust the import path as needed

const ProtectedRoute = () => {
    const { isLoggedIn, isLoading, isAdmin } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, isLoading, navigate]);

    if (isLoading) {
        return <div>Loading...</div>; // You can customize this loading indicator
    }

    return isLoggedIn ? <Outlet /> : null;
};

export default ProtectedRoute;

