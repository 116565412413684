import { Typography, Box, TextField, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import GeneralButton from "../Components/General/Button"
import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useAuth } from "../Context/AuthContext";

export const EditNotation = () => {

  const [billingsData, setBillingsData] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [billingCombination, setbillingCombination] = useState('');
  const [billingNotation, setbillingNotation] = useState('');
  const [billingProperty, setbillingProperty] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [originalData, setOriginalData] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const {isAdmin} = useAuth();


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    console.log(e.target.value)
  };


    // Split the search query into terms (by commas or spaces)
    const searchTerms = searchQuery.split(/[, ]+/).filter(Boolean); // Splits by commas or spaces, ignoring empty terms

    // Filter data based on whether all search terms are found in the billingCombination
    const filteredData = billingsData.filter((item) =>
        searchTerms.every((term) =>
            item.billingCombination.some((value) =>
                value.toLowerCase().includes(term.toLowerCase())
            )
        )
    );




  // Fetch billing data on component mount
  useEffect(() => {
    fetchBillingData();
  }, []);

  // Utility function to fetch billing data
  const fetchBillingData = async () => {
    try {
      const response = await fetch('/api/billing-notation/getBillingNotationItems');
      if (response.ok) {
        const data = await response.json();
        // console.log(data.billingNotationItems)

        setBillingsData(data.billingNotationItems);
        // setBillingsData(...billingsData, data.billingNotationItems)
      } else {
        console.error("HTTP error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  const handleSaveRow = async (index) => {
    console.log("handle save row")
    // Perform API call to update the row data with billingsData[index]
    console.log(billingsData[index])

    try {
      const response = await fetch(`/api/billing-notation/update-billing-notation-item/${index}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        // bad naming for selectedRow Variable
        body: JSON.stringify(billingsData[index]),
      });

      if (response.ok) {
        // Handle the response, update the state, etc.
        console.log("WHOOOO")
        fetchBillingData();

      } else {
        console.error('HTTP error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Network Error:', error.message);
    }
    setOriginalData(null); // Clear the original data
    setEditRowIndex(null);
  };

  const handleCancelEdit = () => {

    if (originalData !== null) {
      const newData = [...billingsData];
      newData[editRowIndex] = originalData; // Restore the original data
      setBillingsData(newData);
      setOriginalData(null); // Clear the original data
    }
    setEditRowIndex(null);
  };
  
  const handleDeleteRow = async (index) => {
    // Perform API call to delete the row at index
    console.log("handle delete row")
    try {
      // Replace '/api/billing-info/delete-billingItem' with your actual API endpoint
      // and ensure it can handle a DELETE request with a billingCombination parameter.
      const response = await fetch(`/api/billing-notation/delete-billing-notation-items/${index}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('Concession Item deleted successfully');
        // Call fetchBillingData to refresh the list after deletion
        fetchBillingData();
      } else {
        console.error('HTTP error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Network Error:', error.message);
    }
    setEditRowIndex(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    console.log("Handle Open Modal")
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // Reset input fields
    setbillingCombination('');
    setbillingNotation('');
    setbillingProperty('')
    setValidationMessage('');
    console.log("Handle Close Modal")
  };

  // const handleSaveModal = async () => {
  //   console.log("Handle Save Modal")
  //   // Check if input fields are empty
  //   if (billingCombination.trim() === '' || billingNotation.trim() === '') {
  //     // If any field is empty, display an error message or take appropriate action
  //     // For example, you can display a snackbar or set an error state to indicate the validation error
  //     alert('Please fill in all fields');
  //     return;
  //   }

  //   // // Split the comma-separated values into an array
  //   let billingCombinationArray = billingCombination.split(/[,\s]+/).map(item => item.trim());
  //   billingCombinationArray = billingCombinationArray.filter(el => (el !== '') && (el !== ' '));

  //   const newObject = { billingCombination: billingCombinationArray, billingNotation: billingNotation };

  //   // setBillingsData([...billingsData,newObject ])
  //   handleCloseModal();
  //   console.log(billingsData)

  //   try {
  //     // Perform API call to save the new data
  //     const response = await fetch('/api/billing-notation/add-billing-notation', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         newBillingObject: newObject
  //       })
  //     });

  //     if (response.ok) {
  //       console.log('Data saved successfully');
  //       // Reset input fields and close modal
  //       handleCloseModal();
  //       // Refetch billing data to update the table
  //       fetchBillingData();
  //     } else {
  //       console.error('Failed to save data:', response.status, response.statusText);
  //       // Handle the error response accordingly
  //     }
  //   } catch (error) {
  //     console.error('Error saving data:', error.message);
  //     // Handle network errors or other exceptions
  //   }
  // };

  const handleSaveModal = async () => {
    if (billingCombination.trim() === '' || billingNotation.trim() === '' || billingProperty.trim() === '') {
      setValidationMessage('Please fill in all fields');
      return;
    }
    
    //Get rid of any potential whitespace
    const billingNotationTrimmed = billingNotation.trim(); 
    const billingPropertyTrimmed = billingProperty.trim();


    let billingCombinationArray = billingCombination.split(/[,\s]+/).map(item => item.trim());
    billingCombinationArray = billingCombinationArray.filter(el => el !== '' && el !== ' ');
  
    billingCombinationArray.sort();
  
    const isDuplicate = billingsData.some(item => {
      const sortedExistingCombination = [...item.billingCombination].sort();
      return sortedExistingCombination.length === billingCombinationArray.length &&
        sortedExistingCombination.every((value, index) => value === billingCombinationArray[index]);
    });
  
    if (isDuplicate) {
      setValidationMessage('This billing combination already exists.');
      return;
    }
  
    const newObject = { billingCombination: billingCombinationArray, billingNotation: billingNotationTrimmed, billingProperty: billingPropertyTrimmed };
  
    try {
      const response = await fetch('/api/billing-notation/add-billing-notation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          newBillingObject: newObject
        })
      });
  
      if (response.ok) {
        console.log('Data saved successfully');
        handleCloseModal();
        fetchBillingData();
      } else {
        console.error('Failed to save data:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
    }
  };
  


  


const handleEditRow = (index) => {
  setEditRowIndex(index);
  setOriginalData({ ...billingsData[index] }); // Store the original data
};


  return (
    <>
      <Typography variant="h3" component="h1" style={{ color: 'black', marginBottom: '50px', textAlign: 'center' }}>
        Billing Notation Settings
      </Typography>

      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            left: '11vw',
            mb: 2, // Adds a little space below the label
            color: 'text.secondary',
            position: 'relative'
          }}
        >
          Search
        </Typography>
        <TextField
          placeholder="Billing combination"
          inputProps={{ 'aria-label': 'search billing number' }}
          sx={{
            input: {
              color: 'black', backgroundColor: '#F1FAF7', fontWeight: 800, borderRadius: '10px', "&::placeholder": {
                color: '#96D8BC'
              }
            }, width: '80%', backgroundColor: '#F1FAF7', borderRadius: '10px', maxWidth: 300, margin: 'auto', left: '11vw'
          }}
          value={searchQuery}
          onChange={handleSearchChange}
        />

        <GeneralButton onClick={handleOpenModal && isAdmin} text={'Add'} variant={"outlined"} disabled={!isAdmin} sx={{
          color: '#56C596', // Text and border color
          borderColor: '#56C596 !important', // Explicit border color
          textTransform: 'none', // Keeps text as provided
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '15.62vw',
          left: '53vw',
          mt: '10px',


          '&:hover': {
            backgroundColor: '#56C596', // Fills the button on hover
            color: 'white', // Changes text color to white on hover
            borderColor: '#56C596', // Ensures border color remains consistent on hover
          }
        }} />

      </Box>
      <Box sx={{ position: 'relative', width: '80%', margin: 'auto', overflowX: 'auto' }}>

        <Table sx={{ backgroundColor: 'white', mt: '25px', borderRadius: '10px' }}>
          <TableHead >
            <TableRow>
              <TableCell>Billing Combination</TableCell>
              <TableCell>Billing Notation</TableCell>
              <TableCell>Propeties</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={index}>
                {editRowIndex === index ? (
                  <>
                    <TableCell>{row.billingCombination.join(',')}</TableCell> {/* Render as text */}
                    <TableCell>
                      <TextField
                        value={row.billingNotation}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const newItems = newValue
                          const newData = [...billingsData];
                          newData[index].billingNotation = newItems;
                          setBillingsData(newData);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                      value={row.billingProperty}
                      onChange={(e) => {
                        const newValue = e.target.value; 
                        const newProperty = newValue
                        const newData = [...billingsData];
                        newData[index].billingProperty = newProperty;
                        setBillingsData(newData);
                      }}
                      >

                      </TextField>
                    </TableCell>
                    

                    <TableCell>
                      <Button onClick={() => handleSaveRow(index)} sx={{ mr: 1 }}>Save</Button>
                      <Button onClick={handleCancelEdit} sx={{ mr: 1 }}>Cancel</Button>
                    </TableCell>

                  </>
                ) : (
                  <>
                    {/* {console.log(billingsData)} */}
                    <TableCell>{row.billingCombination.join(', ')}</TableCell>
                    <TableCell>{row.billingNotation}</TableCell>
                    <TableCell>
                      {row.billingProperty}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditRow(index) && isAdmin} disabled={!isAdmin}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDeleteRow(index) && isAdmin} disabled={!isAdmin}>
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: 20, right: 20 }} onClick={handleOpenModal}>
               <AddIcon />
           </Fab> */}
      </Box>

      {/* Modal */}
      <Dialog open={openModal} onClose={handleCloseModal}>
  <DialogTitle>Add New Item</DialogTitle>
  <DialogContent>
    <TextField
      label="Billing Combination (comma or space separated)"
      value={billingCombination}
      onChange={(e) => setbillingCombination(e.target.value)}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Billing Notation"
      value={billingNotation}
      onChange={(e) => setbillingNotation(e.target.value)}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Billing Property"
      value={billingProperty}
      onChange={(e) => setbillingProperty(e.target.value)}
      fullWidth
      margin="normal"
    />
    {validationMessage && (
      <Typography color="error" variant="body2">
        {validationMessage}
      </Typography>
    )}
  </DialogContent>
  <DialogActions>
    <Box sx={{ mx: 'auto' }}>
      <GeneralButton onClick={handleSaveModal} text={'Save'} sx={{
        backgroundColor: "#56C596",
        borderColor: '#56C596 !important',
        textTransform: 'none',
        width: '4.50vw',
        marginRight: '10px'
      }} />
      <GeneralButton onClick={handleCloseModal} text={'Close'} variant={"outlined"} sx={{
        color: '#56C596',
        borderColor: '#56C596 !important',
        textTransform: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '4.50vw',
        '&:hover': {
          backgroundColor: '#56C596',
          color: 'white',
          borderColor: '#56C596',
        }
      }} />
    </Box>
  </DialogActions>
</Dialog>

    </>


  )
}