export const checkPasswordApi = async (password) => {
    try {
      const response = await fetch(`/api/user/check-password/${password}`, {
        method: 'POST',
        credentials: 'include', // This is crucial for including cookies in the request
        headers: {
          'Content-Type': 'application/json',
          // No need to explicitly include user ID; it's managed server-side via session cookies
        },
        body: JSON.stringify({
          password // The password to check
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      // Assuming the backend sends back a response indicating password correctness
      if (data.isPasswordCorrect) {
        console.log('Password is correct');
        // Handle correct password scenario
        return true; 
      } else {
        console.log('Password is incorrect');
        // Handle incorrect password scenario
        return false;
      }
    } catch (error) {
      console.error('Error checking password:', error);
      // Handle errors, such as displaying an error message
    }
  };
  
  // Example usage
  // checkPassword('password123');
  