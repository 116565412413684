import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GeneralButton from '../General/Button';
const ConcessionItemForm = ({ open, onClose, onAdd }) => {
  const [itemNumber, setItemNumber] = useState('');
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation: Check if item number is empty
    if (!itemNumber.trim()) {
      setError('Please enter an item number');
      return; // Prevent submission
    }
    console.log("Inside handleSubmit")
    console.log(itemNumber)
    onAdd({'billingNumber': itemNumber, 'associatedItems': [10990]});
    setItemNumber('');
    setError('');
    onClose(); // Close the modal after submission
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add item number</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
          {error && <p style={{ color: 'red', marginBottom: '15px' }}>{error}</p>}
          <TextField
            placeholder="Item number"
            value={itemNumber}
            onChange={(e) => setItemNumber(e.target.value)}
            sx={{ margin: '10px 0' }} // Add margin between input fields
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ mx:'auto'}}>
        
        <GeneralButton onClick={handleSubmit} text={'Add'}  sx ={{
          backgroundColor:"#56C596",
          borderColor:'#56C596 !important',
          textTransform: 'none', // Keeps text as provided
          width: '4.50vw',
          }}>Add </GeneralButton>

          <GeneralButton onClick={onClose} text={"Cancel"} variant={"outlined"} sx ={{
          color: '#56C596', // Text and border color
          borderColor: '#56C596 !important', // Explicit border color
          textTransform: 'none', // Keeps text as provided
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '4.50vw',
          '&:hover': {
              backgroundColor: '#56C596', // Fills the button on hover
              color: 'white', // Changes text color to white on hover
              borderColor: '#56C596', // Ensures border color remains consistent on hover
          }
          }}>Cancel</GeneralButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConcessionItemForm;
