import React, { useState, useEffect, useCallback } from 'react'; // Import useCallback here
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, RadioGroup, FormControl, FormLabel, Alert, InputAdornment } from '@mui/material'; // Include InputAdornment in your import from MUI
import { checkForAnotherAdminAPI } from '../services/checkForAnotherAdminApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { checkPasswordApi } from '../services/checkPasswordApi';
import { useAuth } from '../Context/AuthContext';
import {useNavigate } from 'react-router-dom';
import { deleteAdminUser } from '../services/deleteAdminUserApi';
import { deleteOrganisation } from '../services/deleteOrganisationApi';

function DeleteAccount() {
  const [deleteType, setDeleteType] = useState('admin');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isAnotherAdmin, setIsAnotherAdmin] = useState(true); // Assume true until checked
  const [loading, setLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const {logout} = useAuth(); 
  const navigate = useNavigate();

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

    // Debounced password verification
    const verifyPassword = useCallback(debounce( async (enteredPassword) => {
      // Simulated password verification (replace this with your actual API call)
      console.log('Verifying password:', enteredPassword);
      let isPasswordValid;
      
      if (enteredPassword === '') {
        isPasswordValid = false; 
      } else {
         isPasswordValid = await checkPasswordApi(enteredPassword); 
      }
      
      setIsPasswordValid(isPasswordValid)
      // Example: setIsPasswordValid(enteredPassword === 'correctPassword');
    }, 1000), []); // 500ms delay

  useEffect(() => {
    if (deleteType === 'admin') {
      checkForAnotherAdmin();
    } else {
      setIsAnotherAdmin(true); // Reset this flag when switching to organization, assuming it's always okay to proceed
    }
  }, [deleteType]);

  // Simulate a check for another admin in the organization
  const checkForAnotherAdmin = async () => {
    setLoading(true);
    // Simulate asynchronous call to check for another admin
    const hasAnotherAdmin = await checkForAnotherAdminAPI(); // Replace this with your actual logic to check
    console.log("Has another Admin:")
    console.log(hasAnotherAdmin)
    setLoading(false);
    setIsAnotherAdmin(hasAnotherAdmin);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    verifyPassword(newPassword);
  };

  const handleDelete = async () => {
    // Proceed with deletion logic here for either admin or organization
    console.log(`${deleteType} account deletion confirmed`);
    setOpenDialog(false);

    if (deleteType === 'admin') {
      
      await deleteAdminUser(); 
      
    } else {
      await deleteOrganisation();
    }

    logout(); 
    navigate('/')
    
  };

  return (
    <Box sx={{ p: 3, maxWidth: 600, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Delete Account
      </Typography>

      <FormControl component="fieldset">
        <FormLabel component="legend">Select account type to delete</FormLabel>
        <RadioGroup
          row
          aria-label="account-type"
          name="row-radio-buttons-group"
          value={deleteType}
          onChange={(event) => setDeleteType(event.target.value)}
        >
          <FormControlLabel value="admin" control={<Radio />} label="Admin Account" />
          <FormControlLabel value="organization" control={<Radio />} label="Organization Account" />
        </RadioGroup>
      </FormControl>

      <TextField
        label="Confirm Password"
        variant="outlined"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={handlePasswordChange}
        sx={{ mb: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isPasswordValid !== null && (
                isPasswordValid ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />
              )}
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
        label={`I understand the consequences of deleting this ${deleteType} account and wish to proceed.`}
        sx={{ mb: 2 }}
      />
      
      {deleteType === 'admin' && !isAnotherAdmin && (
        <Alert severity="error" sx={{ mb: 2 }}>
          There must be at least one other admin in the organization before you can delete your account.
        </Alert>
      )}

      <Button
        variant="contained"
        color="error"
        disabled={!isPasswordValid || !isChecked || !isAnotherAdmin || loading}
        onClick={() => setOpenDialog(true)}
        sx={{ mt: 1, mb: 2 }}
      >
        Permanently Delete {deleteType === 'admin' ? 'Admin' : 'Organization'} Account
      </Button>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>
          Confirm {deleteType === 'admin' ? 'Admin' : 'Organization'} Account Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to permanently delete this {deleteType} account? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" disabled={!isAnotherAdmin}>Delete Account</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DeleteAccount;
