import React from 'react';
import { Box, FormControl, FormLabel, Typography, RadioGroup, FormControlLabel, Radio, Divider } from '@mui/material';
import IconWithHoverImage from '../HoverImageContainer';
import HealthCareCard from '../../images/HealthCareCard.svg'
import SensiorsCarImage from '../../images/CommonwealthSeniorsCard.svg'
import DVACard from '../../images/DVACard.svg'
import PensionarConcessionCard from '../../images/PensionerConcessionCard.svg'

// ConcessionEligibilitySection component
const ConcessionEligibilitySection = ({ setIsConcessionEligible }) => {

    // Function to handle concession eligibility change
    const handleConcessionEligibilityChange = (event) => {
        // Convert the radio button value to a boolean
        let booleanVal = event.target.value === 'true' ? true : false;
        // Set the concession eligibility state
        setIsConcessionEligible(booleanVal);
    };

    return (

        // Form control for concession eligibility 
        <FormControl component="fieldset" sx={{ textAlign: 'center', height: 'auto' }}>
            {/* Label for the concession eligibility section */}
            <FormLabel component="legend"> <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>Does the patient match any <span style={{ color: '#56C596' }}>any</span> of the </Typography> <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>following criteria?</Typography> </FormLabel>

            <Box sx={{ backgroundColor: 'white', borderRadius: '16px', height: 'auto' }}>
                <ul>




                    <Typography sx={{ margin: '20px' }}>Does this patient have a Health Care Card? <IconWithHoverImage image={HealthCareCard} /></Typography>
                    <Divider sx={{ height: '2px', width: '90%', backgroundColor: '#F5F5F5' }} />
                    <Typography sx={{ margin: '20px' }}>Does this patient have a DVA card? <IconWithHoverImage image={DVACard} /></Typography>
                    <Divider sx={{ height: '2px', width: '90%', backgroundColor: '#F5F5F5' }} />
                    <Typography sx={{ margin: '20px' }}>Does this patient have a Commonwealth Seniors Card? <IconWithHoverImage image={SensiorsCarImage} /></Typography>
                    <Divider sx={{ height: '2px', width: '90%', backgroundColor: '#F5F5F5' }} />
                    <Typography sx={{ margin: '20px' }}>Does this patient have a Pensioner Concession Card? <IconWithHoverImage image={PensionarConcessionCard} /></Typography>
                    <Divider sx={{ height: '2px', width: '90%', backgroundColor: '#F5F5F5' }} />
                    <Typography sx={{ margin: '20px' }}>Is this patient 65 years or older?</Typography>
                    <Divider sx={{ height: '2px', width: '90%', backgroundColor: '#F5F5F5' }} />

                    <Typography sx={{ margin: '20px' }}>Is this patient 15 years or younger?</Typography>
                </ul>
            </Box>
            {/* Radio group for selecting concession eligibility */}
            <RadioGroup row aria-label="concession type" name="row-radio-buttons-group" onChange={handleConcessionEligibilityChange} sx={{ justifyContent: 'center', marginTop: '2vh' }}>
                {/* Radio button for true */}
                <FormControlLabel value={true} control={<Radio sx={{ color: '#56C496' }} />} label="Yes" sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.5rem' } }} />
                {/* Radio button for false */}
                <FormControlLabel value={false} control={<Radio sx={{ color: '#56C496' }} />} label="No" sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.5rem' } }} />
            </RadioGroup>
        </FormControl>


    );
}

// Export the ConcessionEligibilitySection component
export default ConcessionEligibilitySection;
