import Typography from '@mui/material/Typography';
import { useAuth } from '../Context/AuthContext';

export const ManageAccount = () => {

    const {userName, userOrgName} = useAuth();

    console.log(userName);
    console.log(userOrgName);
    return (
        <Typography variant="h3" component="h1" style={{ color: '#333', margin: '20px 0', textAlign: 'center'}}>
        Welcome to your edit account management page
    </Typography>
    )
}

export default ManageAccount;