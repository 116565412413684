





// Utility function to fetch billing data
export const fetchSpecialBillingData = async (setSpecialBillingData) => {
    try {
      const response = await fetch('/api/billing-info/get-special-items', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("This is the special billing data returned from the server!");
        console.log(data);
  
        // Update the billingsData state
        setSpecialBillingData(data.billingItems.specialBillingItems);

      } else {
        console.error("HTTP error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };