import React from 'react';
import Button from '@mui/material/Button';

// A reusable button component
const GeneralButton = ({ text, onClick, variant = 'contained', color = 'primary', ...props }) => {
    return (
        <Button
            variant={variant} 
            color={color}
            onClick={onClick}
            {...props} // allows for additional props like 'disabled', 'size', etc.
        >
            {text}
        </Button>
    );
}

export default GeneralButton;
