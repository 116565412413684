import { Modal, Box, Typography } from "@mui/material";
import GeneralButton from "../../General/Button";


export const DeleteSpecialItemsModal = ({
    open, 
    handleDelete, 
    handleClose, 
    itemInformation
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
      };


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Delete Item
                </Typography>
                
                    <Typography variant="body1" gutterBottom>
                        Are you sure you want to delete this item?
                    </Typography>
              
                <Box sx={{ mx: 'auto' }}>

                    <GeneralButton onClick={() => handleDelete(itemInformation)} text={'Delete'} sx={{
                        backgroundColor: "#56C596",
                        borderColor: '#56C596 !important',
                        textTransform: 'none', // Keeps text as provided
                        width: '4.50vw',
                        marginRight: '10px'
                    }} />
                    <GeneralButton onClick={handleClose} text={'Cancel'} variant={"outlined"} sx={{
                        color: '#56C596', // Text and border color
                        borderColor: '#56C596 !important', // Explicit border color
                        textTransform: 'none', // Keeps text as provided
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        width: '4.50vw',
                        '&:hover': {
                            backgroundColor: '#56C596', // Fills the button on hover
                            color: 'white', // Changes text color to white on hover
                            borderColor: '#56C596', // Ensures border color remains consistent on hover
                        }
                    }} />
                </Box>
            </Box>
        </Modal>
    )
}

