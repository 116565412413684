import React, { useState, createContext, useContext, useEffect } from 'react';

const AuthContext = createContext();

/*
 * The `checkLoginStatus` function is automatically invoked when the `AuthProvider` component mounts,
 * thanks to the useEffect hook with an empty dependency array. This setup ensures that the login status check
 * occurs as soon as the application starts and the `AuthProvider` is rendered into the component tree.
 * This is crucial for protecting routes and managing user sessions effectively. By performing this check
 * at the root of our application context, we ensure that any protected routes or components relying on the
 * authentication state are correctly gated based on the user's current login status. This pattern helps maintain
 * a secure and user-specific experience across the application, redirecting or allowing access to resources
 * as appropriate based on authentication status.
 */


export const AuthProvider = ({ children }) => {
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Added loading state
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState(null);
    const [isVerified, setIsVerified] = useState(null); 

    const [userName, setUserName] = useState(null);
    const [userOrgName, setUserOrgName] = useState(null);

    const login = () => setIsLoggedIn(true);
    const logout = () => setIsLoggedIn(false);

    const checkLoginStatus = async () => {
        setIsLoading(true); // Set loading state on check start
        console.log("Checking user auth")
        try {
            const response = await fetch('/api/user/validate-session', {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            // console.log("We are inside of authcontext")
            console.log(data);
            setIsLoggedIn(data.isValid);
            //console.log("is admin auth", data.sessionData.isAdmin)
            setIsAdmin(data.isValid ? data.sessionData.isAdmin : null); // Set userType if session is valid

            setIsVerified(data.sessionData.isVerified); 

            setUserName(data.sessionData.userName);
            setUserOrgName(data.sessionData.orgName);

        } catch (error) {
            console.error('Error checking login status:', error);
            setIsLoggedIn(false);
        } finally {
            setIsLoading(false); // Set loading state on check completion
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, [isLoggedIn]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, isLoading, isAdmin, setIsAdmin, isVerified, userOrgName, userName }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
