export const deleteUser = async (userId, organisationId) => {
    try {
      const response = await fetch(`api/admin/deleteUser/${userId}/${organisationId}`, {
        method: 'DELETE', // Specify the method
        // Headers may be required depending on your backend setup, e.g., for content-type or authentication
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer <your-token-here>', // If you're using token-based authentication
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log('Success:', result);
        // Handle success scenario, perhaps redirecting the user or displaying a success message
      } else {
        console.error('Error:', response.statusText);
        // Handle server errors or unsuccessful deletions
      }
    } catch (error) {
      console.error('Request failed', error);
      // Handle network errors or other exceptions
    }
  };
  
  // Example usage:
  // deleteUser('userIdValue', 'organisationIdValue');
  