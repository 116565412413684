import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button, Container, Box, TableContainer, Snackbar, CircularProgress, Tooltip } from '@mui/material';
// Other imports remain the same
import { AddUserModal } from './AddUserModal';
import { adminGetUsers } from '../../services/adminGetUsersApi';
import { deleteUser } from '../../services/deleteUserApi';
import { resendVerificationLink } from '../../services/resendVerificationLinkApi';
import GeneralButton from '../../Components/General/Button';
import { useAuth } from '../../Context/AuthContext';
import CustomModal from '../../Components/General/CustomeModal';
import { resendOrganisationSignUpEmail } from '../../services/resendOrganisationVerifyEmail';


export const ManageUsers = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loadingStates, setLoadingStates] = useState({}); // Add this state to track resend loading status by userId
  const {isVerified} = useAuth(); 
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleConfirm = async () => {
      console.log('Action confirmed!');
      handleClose();
      // don't want to have to await sending the email
      const resendEmailResponse = await resendOrganisationSignUpEmail();

      setSnackbarMessage(resendEmailResponse.message)
      setSnackbarOpen(true)
  
    };


  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchUserData = async () => {
    try {
      const fetchedUsers = await adminGetUsers();
      const extractedArray = fetchedUsers.userArr;
      setUsers(extractedArray); // Update the state with fetched users
      console.log(extractedArray)
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  useEffect(() => {
    fetchUserData(); // Fetch user data when the component mounts
    console.log(isVerified)
    if (!isVerified) {
      console.log("Yo")
      handleOpen()
    }
    
  }, [isVerified]);

 // Inside your handleResendVerification function
const handleResendVerification = async (userId) => {
  setLoadingStates(prev => ({ ...prev, [userId]: true })); // Start loading
  const verificationLinkResponse = await resendVerificationLink(userId);
  if (verificationLinkResponse) {
    // Success handling
    setSnackbarMessage('Verification link resent successfully!');
    setSnackbarOpen(true);
  } else {
    // Failure handling
    setSnackbarMessage('Failed to resend verification link.');
    setSnackbarOpen(true);
  }
  setLoadingStates(prev => ({ ...prev, [userId]: false })); // Stop loading
};

  const handleDeleteUser = async (userId, userOrgId) => {
    console.log('Deleting user:', userId);
    await deleteUser(userId, userOrgId);
    await fetchUserData();
  };

  return (
    <>
      <Typography variant="h3" component="h1" style={{ color: '#333', margin: '20px 0', textAlign: 'center', marginTop: '30px' }}>
         Manage Users
      </Typography>

      <Container maxWidth="lg">
        <Box textAlign="right" mb={2}>
   
        <GeneralButton disabled = {isVerified ? false: true} onClick={handleOpenModal} text={'Add User'}   sx ={{
          backgroundColor:"#56C596",
          borderColor:'#56C596 !important',
          textTransform: 'none', // Keeps text as provided
          width: '8.50vw',
          marginRight: '10px',
          }}
          />
        
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Resend Verification Link</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>{user.isAdmin }</TableCell>
                  <Tooltip title={user.status === 'Inactive'? '' : 'User Already Active'}>
                  <TableCell>
                  
                  <Button
        sx ={{
          backgroundColor:"#56C596",
          borderColor:'#56C596 !important',
          textTransform: 'none', // Keeps text as provided
          width: '7.50vw'}}
        onClick={() => handleResendVerification(user.id)}
        disabled={loadingStates[user.id] || user.status === 'Active'} // Disable the button based on the loading state
      >
        {loadingStates[user.id] ? <CircularProgress size={24} /> : 'Resend'}
        
      </Button>
      
                  </TableCell>
                  </Tooltip>
                  <TableCell>
                  
                    <Button  sx ={{

          color: '#56C596', // Text and border color
          borderColor: '#56C596 !important', // Explicit border color
          textTransform: 'none', // Keeps text as provided
          backgroundColor: 'transparent',
          boxShadow: 'none',
           width: '7.50vw',
          '&:hover': {
              backgroundColor: '#56C596', // Fills the button on hover
              color: 'white', // Changes text color to white on hover
              borderColor: '#56C596', // Ensures border color remains consistent on hover
          }}}variant="outlined" color="secondary" onClick={() => handleDeleteUser(user.id, user.organisationId)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <AddUserModal open={modalOpen} handleClose={handleCloseModal} fetchUserData={fetchUserData} />
      <CustomModal isOpen={open} handleClose={handleClose} title ={"Verify Your Account"} message={"Please respond to the verification email sent to your account in order to add more users. Please click below to resend email."} buttonLabel={"Resend Email"} onButtonClick={handleConfirm} /> 
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage} />
    </>
  );
};

export default ManageUsers;
