
// Function to add a new special billing case to an item number
const addSpecialBillingCaseApi = async (newBillingInformation, handleUpdateAllBillings) => {
    try {
       
        const response = await fetch('/api/billing-info/add-special-case', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newBillingInformation),
        });

        // Check if the response is OK (status code 2xx)
        if (response.ok) {
            // Parse the response body as JSON
            const data = await response.json();
            // Return the parsed JSON data
            
            handleUpdateAllBillings();

            return data;
        } else {
            // Throw an error if the response is not OK
                if (response.status === 409) {
                    throw new Error(`This Special Item Already Exists`);
                }

            throw new Error(`HTTP error: ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        // Throw an error if there's a network error or any other error during the request
        throw new Error(`${error.message}`);
    }
};

// Export the function to make it available for use in other modules
export { addSpecialBillingCaseApi };
