import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

// Component for switching billing type
const BillingTypeSwitcher = ({ resetBillingType }) => {
    return (
        // Tooltip for the switch billing type button
        <Tooltip title="Restart process">
            {/* IconButton for switching billing type */}
            <IconButton onClick={resetBillingType} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                {/* SettingsIcon for the switch billing type button */}
                <HomeIcon />
            </IconButton>
        </Tooltip>
    );
}

export default BillingTypeSwitcher;
