// Function to add a new special billing case to an item number
const editSpecialBillingItemApi = async (updateInfo) => {
    try {
       
        const response = await fetch('/api/billing-info/update-special-billingItem', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateInfo),
        });

        // Check if the response is OK (status code 2xx)
        if (response.ok) {
            // Parse the response body as JSON
            const data = await response.json();
            // Return the parsed JSON data
            console.log(data)
            return data.message;

        } else {
            // Throw an error if the response is not OK
            throw new Error(`HTTP error: ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        // Throw an error if there's a network error or any other error during the request
        throw new Error(`Network error: ${error.message}`);
    }
};

// Export the function to make it available for use in other modules
export { editSpecialBillingItemApi };
