import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip, Box, Typography,
    TextField, Button, Fab, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GeneralButton from '../Components/General/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../Context/AuthContext';


const EditConcessionBillings = () => {
    const [billingsData, setBillingsData] = useState([]);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [billingNumber, setbillingNumber] = useState('');
    const [associatedItems, setAssociatedItems] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const {isAdmin} = useAuth();

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = billingsData.filter((item) =>
        item && item.billingNumber && typeof item.billingNumber === 'string'
            ? item.billingNumber.toLowerCase().includes(searchQuery.toLowerCase())
            : false
    );
    // Fetch billing data on component mount
    useEffect(() => {
        fetchBillingData();
    }, []);

    // Utility function to fetch billing data
    const fetchBillingData = async () => {
        try {
            const response = await fetch('/api/concession-billing-items/get-concession-items');
            if (response.ok) {
                const data = await response.json();
                setBillingsData(data.billingItems);
            } else {
                console.error("HTTP error:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Network Error:", error.message);
        }
    };

    const handleEditRow = (index) => {
        setEditRowIndex(index);
    };

    const handleSaveRow = async (index) => {
        // Perform API call to update the row data with billingsData[index]
        console.log(billingsData[index])
        

        try {
            const response = await fetch(`/api/concession-billing-items/update-concession-item/${billingsData[index].billingNumber}`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              // bad naming for selectedRow Variable
              body: JSON.stringify(billingsData[index]),
            });
      
            if (response.ok) {
              // Handle the response, update the state, etc.
              console.log("WHOOOO")
              fetchBillingData();
              
            } else {
              console.error('HTTP error:', response.status, response.statusText);
            }
          } catch (error) {
            console.error('Network Error:', error.message);
          }

          setEditRowIndex(null);    
    };

    const handleCancelEdit = () => {
        setEditRowIndex(null);
    };

    const handleDeleteRow = async (index) => {
        // Perform API call to delete the row at index
        try {
            // Replace '/api/billing-info/delete-billingItem' with your actual API endpoint
            // and ensure it can handle a DELETE request with a billingNumber parameter.
            const response = await fetch(`/api/concession-billing-items/delete-concession-item/${billingsData[index].billingNumber}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
            });
      
            if (response.ok) {
              console.log('Concession Item deleted successfully');
              // Call fetchBillingData to refresh the list after deletion
              fetchBillingData();
            } else {
              console.error('HTTP error:', response.status, response.statusText);
            }
          } catch (error) {
            console.error('Network Error:', error.message);
          }
          setEditRowIndex(null); 
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        // Reset input fields
        setbillingNumber('');
        setAssociatedItems('');
        
    };

    const handleCloseSnackBar = () => {
        setSnackbarMessage("");
        setSnackbarSeverity("");
        setSnackbarOpen(false)
    }
    const handleSetSnackBar = (snackBarMessage, snackbarSeverity, snackBarOpen) => {
        setSnackbarMessage(snackBarMessage);
        setSnackbarSeverity(snackbarSeverity);
        setSnackbarOpen(snackBarOpen);
    }

    const handleSaveModal = async () => {
   


        try {
                 // Check if input fields are empty
        if (billingNumber.trim() === '' || associatedItems.trim() === '') {
            // If any field is empty, display an error message or take appropriate action
            // For example, you can display a snackbar or set an error state to indicate the validation error
            alert('Please fill in all fields');
            return;
        }
    
        // Split the comma-separated values into an array
        let associatedItemsArray = associatedItems.split(/[,\s]+/).map(item => item.trim());

        associatedItemsArray = associatedItemsArray.filter(el => (el !== '') && (el!== ' '))
        console.log("Concession Billing Information Below")
        console.log(billingsData)
        setbillingNumber(billingNumber.trim())
        
        // if (billingsData.find(obj => obj.billingNumber === billingNumber)) {
        //     setSnackbarMessage("This Item Already Exists");
        //     setSnackbarSeverity("error");
        //     setSnackbarOpen(true)
        //     return;
        // }

            // Perform API call to save the new data
            const response = await fetch('/api/concession-billing-items/add-concession-items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    billingNumber: billingNumber,
                    associatedItems: associatedItemsArray // Send the array to the server
                })
            });
    
            if (response.ok) {
                console.log('Data saved successfully');
                console.log(response.message)
                // Reset input fields and close modal
                // handleCloseModal();
                // Refetch billing data to update the table
                fetchBillingData();
            } else {
                console.error('Failed to save data:', response.status, response.statusText);
                // Handle the error response accordingly
                console.log(response);
                handleSetSnackBar(response.statusText, 'error', true);

            }
        } catch (error) {
            console.error('Error saving data:', error.message);
            // Handle network errors or other exceptions
        } finally {
            handleCloseModal();
        }
    };
    
    

    return (
        <>
             <Typography variant="h3" component="h1" style={{ color: 'black', marginBottom: '50px', textAlign: 'center' }}>
        Concession Billing Settings
      </Typography>
      <Box>
          <Typography
            variant="subtitle2"
            sx={{
              left:'11vw',
              mb: 2, // Adds a little space below the label
              color: 'text.secondary', 
              position: 'relative'
            }}
          >
            Search
          </Typography>
          <TextField
            placeholder="Billing number"
            inputProps={{ 'aria-label': 'search billing number' }}
            sx={{
              input: {
                color: 'black', backgroundColor: '#F1FAF7', fontWeight: 800, borderRadius: '10px', "&::placeholder": {
                  color: '#96D8BC'
                }
              }, width: '80%', backgroundColor: '#F1FAF7', borderRadius: '10px', maxWidth: 300, margin: 'auto', left:'11vw'
            }}
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <GeneralButton onClick={isAdmin && handleOpenModal} text={'Add'} disabled={!isAdmin} variant={"outlined"} sx={{
            color: '#56C596', // Text and border color
            borderColor: '#56C596 !important', // Explicit border color
            textTransform: 'none', // Keeps text as provided
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: '15.62vw',
            left:'53vw',
            mt: '10px',
           
            
            '&:hover': {
              backgroundColor: '#56C596', // Fills the button on hover
              color: 'white', // Changes text color to white on hover
              borderColor: '#56C596', // Ensures border color remains consistent on hover
            }
          }}/>

        </Box>
            <Box sx={{ position: 'relative', width: '80%', margin: 'auto', overflowX: 'auto' }}>
           
                <Table sx ={{backgroundColor: 'white', mt:'25px', borderRadius: '10px'}}>
                    <TableHead >
                        <TableRow>
                            <TableCell>Billing Number</TableCell>
                            <TableCell>Bulk Billing Incentives</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                {editRowIndex === index ? (
                                    <>
                                        <TableCell>{row.billingNumber}</TableCell> {/* Render as text */}
                                        <TableCell>
                                            <TextField
                                                value={row.associatedItems.join(',')}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    const newItems = newValue.split(',').map(item => item.trim()); // Trim spaces around each item
                                                    const newData = [...billingsData];
                                                    newData[index].associatedItems = newItems;
                                                    setBillingsData(newData);
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Button onClick={() => handleSaveRow(index) } sx={{ mr: 1 }}>Save</Button>
                                            <Button onClick={handleCancelEdit} sx={{ mr: 1 }}>Cancel</Button>
                                        </TableCell>

                                    </>
                                ) : (
                                    <>
                                        <TableCell>{row.billingNumber}</TableCell>
                                        <TableCell>{row.associatedItems.join(', ')}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => handleEditRow(index) && isAdmin} disabled={!isAdmin}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => handleDeleteRow(index) && isAdmin} disabled={!isAdmin}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/* <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: 20, right: 20 }} onClick={handleOpenModal}>
                    <AddIcon />
                </Fab> */}
            </Box>
            <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          onClose={handleCloseSnackBar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
            
            {/* Modal */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Add New Item</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Item Number"
                        value={billingNumber}
                        onChange={(e) => setbillingNumber(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Associated Items"
                        value={associatedItems}
                        onChange={(e) => setAssociatedItems(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                <Box sx={{ mx:'auto'}}>
                    <GeneralButton onClick={handleSaveModal} text={'Save'}   sx ={{
          backgroundColor:"#56C596",
          borderColor:'#56C596 !important',
          textTransform: 'none', // Keeps text as provided
          width: '4.50vw',
          marginRight: '10px'
          }}/>
          <GeneralButton onClick={handleCloseModal} text={'Close'} variant={"outlined"}  sx ={{
          color: '#56C596', // Text and border color
          borderColor: '#56C596 !important', // Explicit border color
          textTransform: 'none', // Keeps text as provided
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '4.50vw',
          '&:hover': {
              backgroundColor: '#56C596', // Fills the button on hover
              color: 'white', // Changes text color to white on hover
              borderColor: '#56C596', // Ensures border color remains consistent on hover
          }
          }}/>
          </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditConcessionBillings;
