const logoutUser = async (logoutCallback, navigateCallback) => {
    try {
        const response = await fetch('/api/user/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (logoutCallback) logoutCallback();
            if (navigateCallback) navigateCallback('/');
        } else {
            console.error("HTTP error:", response.status, response.statusText);
        }
    } catch (error) {
        console.error("Logout error:", error);
    }
};

export { logoutUser };
