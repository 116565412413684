import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, MenuItem, Select, FormControl, InputLabel, Snackbar } from '@mui/material';
import { addUserApi } from '../../services/addUserApi';

export const AddUserModal = ({ open, handleClose, fetchUserData }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [admin, setAdmin] = useState(false); // This will be true if "Admin" is selected
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for the Snackbar message

    const handleUserTypeChange = (event) => {
        setAdmin(event.target.value === 'admin');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const userToSubmit = {
            firstName,
            lastName,
            email,
            password: 'defaultPassword', // This should be handled more securely in real applications
            admin,
            date: new Date(),
        };

        try {
            console.log(userToSubmit)
            const response = await addUserApi(userToSubmit);
            console.log(response)
            if (response.ok) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                await fetchUserData();
              
            } else {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error adding user:', error);
            setSnackbarMessage('Error adding user');
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false);
              // Clear the form fields
              setFirstName('');
              setLastName('');
              setEmail('');
              setAdmin(false);
            handleClose();
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableRestoreFocus>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            margin="dense"
                            id="firstName"
                            label="First Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <TextField
                            margin="dense"
                            id="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <FormControl fullWidth margin="normal" required variant="outlined">
                            <InputLabel id="userType-label">User Type</InputLabel>
                            <Select
                                labelId="userType-label"
                                id="userType"
                                value={admin ? 'admin' : 'user'}
                                label="User Type"
                                onChange={handleUserTypeChange}
                            >
                                <MenuItem value="user">User</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" color="primary" variant="contained" fullWidth style={{ marginTop: '20px',  backgroundColor:"#56C596", }} disabled={isLoading}>
                            {isLoading ? 'Adding...' : 'Add User'}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </>
    );
};
