import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './Pages/Root';
import HomePage from './Pages/Home';
import { AuthProvider } from './Context/AuthContext';
import UserHome from './Pages/UserHome';
import EditBillings from './Pages/EditBillings';
import WalkThrough from './Pages/WalkThough';
import ManageUsers from './Pages/ManageUsersAdmin/ManageUsersAdmin';
import ManageAccount from './Pages/ManageAccount';
import FAQ from './Pages/FAQ';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import theme from './Style/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Calculator from './Pages/Calculator/Calculator';
import EditBillingsConcessionBillings from './Pages/EditConcessionBillings'
import { UserVerify } from './Pages/UserVerification';
import DeleteAccount from './Pages/ManageAdminAccount';
import AdminProtectedRoute from './Components/Auth/AdminProtectedRoute';
import { ResetPassword } from './Pages/PasswordReset';
import { SendResetPassword } from './Pages/SendResetPassword';
import { Login } from './Pages/Login';
import { EditNotation } from './Pages/EditNotations';
import { Notate } from './Pages/Notate';


function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <RootLayout />,
            children: [
                { path: '/', element: <HomePage /> },
                { path: '/verify', element: <UserVerify /> },
                { path: '/send-reset-password', element: <SendResetPassword /> },
                { path: '/reset-password', element: <ResetPassword /> },
                { path: '/login', element: <Login/>},

                {
                    element: <AdminProtectedRoute />,
                    children: [
                        { path: '/admin-delete-account', element: <DeleteAccount /> }, 
                        { path: '/manage-users', element: <ManageUsers /> }
                    ]
                },
                {
                    element: <ProtectedRoute />,
                    children: [
                        { path: '/calculate', element: <Calculator /> },
                        { path: '/edit-billings', element: <EditBillings /> },
                        { path: '/edit-notation', element: <EditNotation/>},
                        { path: '/edit-concession-billings', element: <EditBillingsConcessionBillings /> },
                        { path: '/walkthrough', element: <WalkThrough /> },
                        // { path: '/manage-users', element: <ManageUsers /> },
                        { path: '/manage-account', element: <ManageAccount /> },
                        { path: '/faqs', element: <FAQ /> },
                        { path: '/home', element: <UserHome /> },
                        {path: '/billing-notation', element: <Notate/>}
                    ]
                }
            ]
        }
        
    ]);

    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <RouterProvider router={router} />
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;
