import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import GeneralButton from "../Components/General/Button";
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";

import { useAuth } from '../Context/AuthContext'; // Adjust the import path as needed for useAuth
import Box from '@mui/material/Box';
import UserHomeImgage from '../images/UserHomeImage.svg'
import { Button } from '@mui/base';


export const UserHome = () => {

    const navigate = useNavigate(); 

    const handleGetbilling = () => {
        navigate('/calculate')
    }

    return (
        <div>
        <Box
        sx={{
            position: 'absolute',
            left: '16.3vw', // X coordinate
            top: '38.14vh', // Y coordinate
            width: '25vw', // Adjusted as needed
            display: 'grid', // Using grid to place items
            gridTemplateColumns: 'max-content', // Fit content width, adjust as needed
            gap: '0px', // Adjust the vertical gap as needed
        }}
    >
        {/* Medicare */}
        <Typography
            component="h1"
            sx={{
                fontFamily: 'Manrope, sans-serif',

                fontSize: '65px',
                margin: 0,
                textAlign: 'left',
            }}
        >
            Medicare billing
        </Typography>
        {/* Billing Calculator, wrapped in a Box for additional control */}
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
    <Typography
        component="span" // Using span to keep inline with flexbox
        sx={{
            fontFamily: 'Manrope, sans-serif',
            fontSize: '65px',
            margin: 0,
            textAlign: 'left',
            whiteSpace: 'nowrap', // Ensures "Billing Calculator" stays on one line
        }}
    >
        made{' '}
        <Typography
            component="span"
            variant="inherit" // Inherit typography variant
            sx={{
                color: '#56C596', // Set color to #56C596
            }}
        >
            simple
        </Typography>
    </Typography>
  
</Box>


<Grid container justifyContent="left" spacing={2}>
            <Grid item>
                <GeneralButton onClick={handleGetbilling} text={"Get billing"} sx={{
                    backgroundColor: '#56C596', // Button Color
                    color: 'white', // Text Color
                    textTransform: 'none',
                    boxShadow: 'none',
                    width: '9.5vw', // Set width
                    height: '6.1vh', // Set height
                    '&:hover': {
                        backgroundColor: 'darken(#56C596, 0.2)', // Adjust for hover state
                    }
                }} />
            </Grid>
           
        </Grid>
</Box>
<Box
                sx={{
                    width: '33.43vw', // Set width
                    height: '33.67vh', // Set height
                    position: 'absolute', // Use absolute positioning
                    left: '50vw', // X coordinate
                    top: '33.14vh', // Y coordinate
                }}
            >
                <img src={UserHomeImgage} alt="Your SVG Description" style={{ width: '100%', height: '100%' }} />
                {/* If SVG is in the public folder, use '/example.svg' in src */}
            </Box>


       

        </div>
    )
}

export default UserHome;