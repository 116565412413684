import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    TextField,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import AutoComplete from './AutoComplete'; // Assuming you have AutoComplete component
import ItemForm from '../ItemForm/ItemForm'; // Assuming you have ItemForm component
import DeleteIcon from '@mui/icons-material/Delete';
import GeneralButton from '../General/Button';

const MixedBillingSelectItem = ({
    billingType,
    billingTypeSelected,
    showCalculation,
    handleReset,
    error,
    values,
    selectedItems,
    setSelectedItems,
    setValues,
    setCalculationResults,
    setShowCalculation,
    setError,
    mixedBillingType,
    setShowCustomGapResults,
    setCustomGapResults,
    setShowCustomPrivateResults, 
    setCustomPrivateResults
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItemsUI, setSelectedItemsUI] = useState([]);
    const [customGap, setCustomGap] = useState(false);
    const [customPrivate, setCustomPrivate] = useState(false);


    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const [adjustedValues, setAdjustedValues] = useState([]);



    const adjustValues = (input) => {
        const totalGap = values.reduce((acc, value) => acc + (value.privateFee - value.mbsRebate), 0);
        const ratio = input / totalGap;

        const newValues = values.map(value => ({
            ...value,
            adjustedGap: (value.privateFee - value.mbsRebate) * ratio,
            adjustedPrivateFee: value.mbsRebate + ((value.privateFee - value.mbsRebate) * ratio)
        }));

        setAdjustedValues(newValues);
    };

    const adjustValuesCustomPrivate = (input) => {

        const totalPrivate= values.reduce((acc, value) => acc + (value.privateFee), 0);
        const ratio = input / totalPrivate;

        const newValues = values.map(value => ({
            ...value,
            adjustedGap: (value.privateFee * ratio) - value.mbsRebate, 
            adjustedPrivateFee: value.privateFee * ratio
        }));

        setAdjustedValues(newValues);
    }








    const calculateTotals = (values) => {
        console.log(values)
        return values.reduce((acc, value) => {
            acc.mbsRebate += value.mbsRebate;
            acc.privateFee += value.privateFee;
            acc.gap += value.privateFee - value.mbsRebate;
            acc.adjustedPrivateFee += value.adjustedPrivateFee || value.privateFee;
            acc.adjustedGap += value.adjustedPrivateFee - value.mbsRebate || value.privateFee - value.mbsRebate;
            console.log(acc)
            return acc;
        }, { mbsRebate: 0, privateFee: 0, gap: 0, adjustedPrivateFee: 0, adjustedGap: 0 });
    };



    const handleItemClick = (index, billingNumber) => {
        if (selectedItemsUI.includes(index)) {
            setSelectedItemsUI(selectedItemsUI.filter(item => item !== index)); // Remove the index from the array if it's already selected
        } else {
            setSelectedItemsUI([...selectedItemsUI, index]); // Add the index to the array if it's not already selected
        }
        handleSelectItem(billingNumber);
    };

    const handleFormAdd = (newItem) => {
        setValues(oldValues => [...oldValues, newItem]);
    };

    const handleValueChange = (newValue) => {
        if (newValue != null) {
            setValues(oldValues => [...oldValues, newValue]);
        }
    };

    const handleSelectItem = (itemNumber) => {
        if (selectedItems.includes(itemNumber)) {
            setSelectedItems(selectedItems.filter(num => num !== itemNumber));
        } else {
            setSelectedItems([...selectedItems, itemNumber]);
        }
    };

    const handleDelete = (index) => {
        const itemToRemove = values[index].billingNumber;

        setValues(oldValues => oldValues.filter((_, i) => i !== index));

        const newSelectedItems = selectedItems.filter(itemNumber => itemNumber !== itemToRemove);

        setSelectedItems(newSelectedItems);
        setSelectedItemsUI(selectedItemsUI.filter(item => item !== index));

        if (newSelectedItems.length === 0) {
            setCalculationResults(null);
        }
    };

    const calculateCustomGap = () => {
        setCustomGap(true);
        setAdjustedValues(values.map(value => ({
            ...value,
            adjustedGap: value.privateFee - value.mbsRebate
        })));
    };

    const handleShowCustomGapResults = () => {
        console.log(adjustedValues)
        setCustomGapResults(adjustedValues)
        setShowCustomGapResults(true);
    }

    const handleShowCustomPrivateResults = () => {
        setCustomPrivateResults(adjustedValues);
        setShowCustomPrivateResults(true);
    }

    const calculateCustomPrivate = () => {
        setCustomPrivate(true);
        setAdjustedValues(values.map(value => ({
            ...value,
            adjustedGap: value.adjustedPrivateFee - value.mbsRebate
        })));
        
    }



    const handleCalculate = () => {
        if (mixedBillingType === 'custom-gap') {
            calculateCustomGap();
            return;
        }

        if (mixedBillingType === 'custom-private') {
            calculateCustomPrivate();
            return;
        }

        // Existing calculation logic...
        if (selectedItems.length === 0) {
            setError('Please Select at Least One Item to Allocate Across');
            return;
        }
        setError('');
        let outOfPocket = 0;
        let selectedValues = values.filter(value => selectedItems.includes(value.billingNumber));
        for (let item of selectedValues) {
            outOfPocket += (item.privateFee - item.mbsRebate);
        }
        let outOfPocketSplit = outOfPocket / values.length;
        let totalCost = 0;
        let totalRebate = 0;
        for (let item of values) {
            totalCost += outOfPocketSplit + item['mbsRebate'];
            totalRebate += item['mbsRebate'];
            item['OPS'] = outOfPocketSplit;
        }
        setCalculationResults({ outOfPocketSplit, totalCost, totalRebate, values });
        setShowCalculation(true); // Show calculation results when Calculate button is clicked.
    };

    return (
        <>
            {!customGap && !customPrivate && <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black', marginBottom: '40px' }}>
                Add items for your billing below
            </Typography>}
            <Box sx={{ mx: 'auto' }}>  {/* Centering and limiting width */}
                {!customGap && !customPrivate && <Typography
                    variant="subtitle2"
                    sx={{
                        mx: 'auto',
                        textAlign: 'left', // Aligns the text to the left
                        mb: 1, // Adds a little space below the label
                        color: 'text.secondary'
                    }}
                >
                    Item Number
                </Typography>}

                {!customGap && !customPrivate && <AutoComplete setValue={handleValueChange} openModal={setOpenDialog} />}
                {openDialog && <ItemForm onAdd={handleFormAdd} onClose={() => setOpenDialog(false)} onOpen={() => setOpenDialog(true)} />}
                {error && !showCalculation && <Typography color="error">{error}</Typography>}

                {!showCalculation && values.length > 0 && !customGap && !customPrivate && (
                    <div>
                        <List sx={{ borderRadius: '10px', mx: 'auto' }}>
                            {values.map((value, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        my: 2,
                                        borderRadius: '10px',
                                        backgroundColor: mixedBillingType === 'split-across' && selectedItemsUI.includes(index) ? '#f0f0f0' : 'white', // Change color if item is selected
                                        boxShadow: mixedBillingType === 'split-across' && selectedItemsUI.includes(index) ? '0 2px 10px rgba(0,0,0,0.2)' : 'none', // Apply shadow if item is selected
                                        border: mixedBillingType === 'split-across' && selectedItemsUI.includes(index) ? '2px solid #56C596' : 'none', // Red border if item is selected
                                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease',
                                        '&:hover': mixedBillingType === 'split-across' ? {
                                            backgroundColor: '#f0f0f0',
                                            boxShadow: '0 2px 10px #56C596',
                                            border: '2px solid #56C596' // Also show the red border on hover
                                        } : {},
                                        cursor: mixedBillingType === 'split-across' ? 'pointer' : 'default' // Change the cursor to pointer to indicate clickable items
                                    }}
                                    onClick={() => mixedBillingType === 'split-across' && handleItemClick(index, value.billingNumber)}
                                >
                                    <ListItemText
                                        primary={`Item Number: ${value.billingNumber}`}
                                        primaryTypographyProps={{ margin: '10px', fontSize: '1.6rem' }}
                                        secondary={
                                            <div style={{ fontWeight: 'bold' }}> {/* Add bold styling here */}
                                                <div style={{ marginBottom: '10px' }}>{`MBS Rebate: $${value.mbsRebate}`}</div>
                                                <div style={{ marginBottom: '10px' }}>{`Private Fee: $${value.privateFee}`}</div>
                                                <div style={{ marginBottom: '10px' }}>{`Gap: $${value.privateFee - value.mbsRebate}`}</div>
                                            </div>
                                        }
                                        secondaryTypographyProps={{
                                            fontSize: '1.1rem',
                                            margin: '15px'
                                        }}
                                    />
                                    <ListItemSecondaryAction >
                                        <Tooltip title="Delete">
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleDelete(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        <GeneralButton
                            variant="contained"
                            color="primary"
                            text={"Next"}
                            onClick={handleCalculate}
                            sx={{
                                backgroundColor: "#56C596",
                                borderColor: '#56C596 !important',
                                textTransform: 'none', // Keeps text as provided
                                width: '7.50vw',
                                mt: 3, // Add some margin top for spacing
                            }}
                        />
                    </div>
                )}
            </Box>



            {customGap && (
                <Box sx={{ mx: 'auto', mt: 4 }}>
                    <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
                        Custom Gap Billing Details
                    </Typography>
                    <TextField
                        label="Enter Custom Value"
                        variant="outlined"
                        fullWidth
                        value={inputValue}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value) && (value === '' || Number(value) >= 0)) { // Allows only numbers and decimal points, ensures zero or greater
                                setInputValue(value);
                                setInputError(false);
                                if (value) {
                                    adjustValues(Number(value));
                                } else {
                                    setAdjustedValues(values.map(value => ({
                                        ...value,
                                        adjustedGap: value.privateFee - value.mbsRebate
                                    })));
                                }
                            } else {
                                setInputError(true);
                            }
                        }}
                        sx={{ mb: 3 }}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Ensures only numeric input
                        error={inputError}
                        helperText={inputError ? "Please enter a value of zero or greater." : ""}
                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Number</TableCell>
                                <TableCell>MBS Rebate</TableCell>
                                <TableCell>Original Private Fee</TableCell>
                                <TableCell>Original Gap</TableCell>
                                <TableCell>Adjusted Private Fee</TableCell>
                                <TableCell>Adjusted Gap</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {adjustedValues.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell>{value.billingNumber}</TableCell>
                                    <TableCell>${value.mbsRebate}</TableCell>
                                    <TableCell>${value.privateFee}</TableCell>
                                    <TableCell>${value.privateFee - value.mbsRebate}</TableCell>
                                    <TableCell>${(value.adjustedPrivateFee || value.privateFee).toFixed(2)}</TableCell>
                                    <TableCell>${value.adjustedGap.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                            {adjustedValues.length > 0 && (
                                <TableRow>
                                    <TableCell><strong>Total</strong></TableCell>
                                    <TableCell>${calculateTotals(values).mbsRebate.toFixed(2)}</TableCell>
                                    <TableCell>${calculateTotals(values).privateFee.toFixed(2)}</TableCell>
                                    <TableCell>${calculateTotals(values).gap.toFixed(2)}</TableCell>
                                    <TableCell>${calculateTotals(adjustedValues).adjustedPrivateFee.toFixed(2)}</TableCell>
                                    <TableCell>${inputValue || calculateTotals(values).gap.toFixed(2)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    {/* <Typography sx={{ mt: 2 }}>
                        {inputValue}
                    </Typography> */}
                    {inputValue.length > 0 && <GeneralButton
                        variant="contained"
                        color="primary"
                        text={"Next"}
                        onClick={handleShowCustomGapResults}
                        sx={{
                            backgroundColor: "#56C596",
                            borderColor: '#56C596 !important',
                            textTransform: 'none', // Keeps text as provided
                            width: '7.50vw',
                            mt: 3, // Add some margin top for spacing
                        }}
                    />}
                </Box>
            )}

{customPrivate && (
                <Box sx={{ mx: 'auto', mt: 4 }}>
                    <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
                        Custom Private Fee Billing Details
                    </Typography>
                    <TextField
                        label="Enter Custom Value"
                        variant="outlined"
                        fullWidth
                        value={inputValue}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value) && (value === '' || Number(value) >= 0)) { // Allows only numbers and decimal points, ensures zero or greater
                                setInputValue(value);
                                setInputError(false);
                                if (value) {
                                    adjustValuesCustomPrivate(Number(value));
                                  
                                } else {
                                    setAdjustedValues(values.map(value => ({
                                        ...value,
                                        adjustedGap: value.privateFee - value.mbsRebate
                                    })));
                                }
                            } else {
                                setInputError(true);
                            }
                        }}
                        sx={{ mb: 3 }}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Ensures only numeric input
                        error={inputError}
                        helperText={inputError ? "Please enter a value of zero or greater." : ""}
                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Number</TableCell>
                                <TableCell>MBS Rebate</TableCell>
                                <TableCell>Original Private Fee</TableCell>
                                <TableCell>Original Gap</TableCell>
                                <TableCell>Adjusted Private Fee</TableCell>
                                <TableCell>Adjusted Gap</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {adjustedValues.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell>{value.billingNumber}</TableCell>
                                    <TableCell>${value.mbsRebate}</TableCell>
                                    <TableCell>${value.privateFee}</TableCell>
                                    <TableCell>${value.privateFee - value.mbsRebate}</TableCell>
                                    <TableCell>${(value.adjustedPrivateFee || value.privateFee).toFixed(2)}</TableCell>
                                    <TableCell>${(value.adjustedGap|| value.privateFee - value.mbsRebate).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                            {adjustedValues.length > 0 && (
                                <TableRow>
                                    <TableCell><strong>Total</strong></TableCell>
                                    <TableCell>${calculateTotals(values).mbsRebate.toFixed(2)}</TableCell>
                                    <TableCell>${calculateTotals(values).privateFee.toFixed(2)}</TableCell>
                                    <TableCell>${calculateTotals(values).gap.toFixed(2)}</TableCell>
                                    <TableCell>${calculateTotals(adjustedValues).adjustedPrivateFee.toFixed(2)}</TableCell>
                                    <TableCell>${ calculateTotals(adjustedValues).adjustedGap.toFixed(2)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    {/* <Typography sx={{ mt: 2 }}>
                        {inputValue}
                    </Typography> */}
                    {inputValue.length > 0 && <GeneralButton
                        variant="contained"
                        color="primary"
                        text={"Next"}
                        onClick={handleShowCustomPrivateResults}
                        sx={{
                            backgroundColor: "#56C596",
                            borderColor: '#56C596 !important',
                            textTransform: 'none', // Keeps text as provided
                            width: '7.50vw',
                            mt: 3, // Add some margin top for spacing
                        }}
                    />}
                </Box>
            )}





        </>
    );
};

export default MixedBillingSelectItem;
