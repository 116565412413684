import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '../Components/Layout/SideBar';
import { useAuth } from '../Context/AuthContext'; // Import useAuth

const RootLayout = () => {
    const { isLoggedIn } = useAuth(); // Use the useAuth hook

    return (
        <>
            {isLoggedIn && <SideBar />} {/* Conditionally render the Sidebar */}
            <main>
                <Outlet />
            </main>
        </>
    );
}

export default RootLayout;
