import React, {useState} from 'react';
import {  Box, Button, IconButton, List, ListItem, ListItemSecondaryAction, Tooltip, ListItemText, Typography, CircularProgress  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConcessionAutoCompleteComponent from './ConcessionAutoComplete';
import ConcessionItemForm from '../ItemForm/ConcessionItemForm';
import { retrieveBillingNotationCombination } from '../../services/retrieveBillingNotationCombinationAPI';

const ConcessionItemsSection = ({
    concessionValues, // Array of concession values
    setShowConcessionItemModal, // Function to set the visibility of the modal
    setConcessionValues, // Function to set the array of concession values
    setSeeBulkBillingIncentives, // Function to trigger seeing bulk billing incentives
    showConcessionItemModal, // Boolean indicating if the modal is visible
    setConcessionItemCombinationResults // setter function for setting the result of the combination search
}) => {

    const [loading, setLoading] = useState(false);

    // Function to handle deletion of a concession item by index
    const handleConcessionDelete = (index) => {
        // Extract the billing number of the item to be removed
        // eslint-disable-next-line 
        const concessionItemToRemove = concessionValues[index].billingNumber;
        // Remove the item from the concession values array
        setConcessionValues(oldValues => oldValues.filter((_, i) => i !== index));
    }

    // Function to handle opening the concession item modal
    const handleOpenConcessionItemModal = () => {
        setShowConcessionItemModal(true);
    };

    // Function to handle closing the concession item modal
    const handleCloseConcessionItemModal = () => {
        setShowConcessionItemModal(false);
    };

    // Function to handle changes in concession values
    const handleConcessionValueChange = (newValue) => {
        // If the new value is not null, add it to the concession values array
        console.log(newValue)
        console.log(concessionValues)
        if (newValue != null) {
            setConcessionValues(oldValues => [...oldValues, newValue]);
        }
    };

    // Function to handle seeing bulk billing incentives
    const handleSeeBulkBillingIncentives = async () => {
      

        try {
        setLoading(true)
            // Make request to set the relevant billing notatation.
        const result = await retrieveBillingNotationCombination(concessionValues); 

        console.log("RESULT below");
        console.log(result);
        // console.log("Yo")  
        // set the result
        setConcessionItemCombinationResults((prevArray) => [...prevArray, ...result]);

        setLoading(false)
        setSeeBulkBillingIncentives(true);
        
        // console.log("Yo")      
        } catch (error) {
            throw new Error;
        } 
    }

    return (
        <Box>
            {/* Instruction to add item numbers */}
            <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
      Please add the item numbers you are<br />
      billing:
    </Typography>
            <br />
            {/* Component for auto-complete input */}
            <ConcessionAutoCompleteComponent setValue={handleConcessionValueChange} addItem = {handleOpenConcessionItemModal}/>
            <br />
            {/* List to display added concession items */}
            <List sx={{ borderRadius: '10px', maxWidth: 300, mx:'auto'}}>
                {concessionValues.map((item, index) => (
                    <ListItem key={index} sx={{ my: 2,  borderRadius: '10px', backgroundColor: 'white', }}>
                        <ListItemText primary={`Item Number: ${item.billingNumber}`} />
                        <ListItemSecondaryAction>
                            {/* Button to delete a concession item */}
                            <Tooltip title="Delete">
                                <IconButton edge="end" aria-label="delete" onClick={() => handleConcessionDelete(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                       
                    </ListItem>
                    
                ))}
            </List>
            {/* Button to add new concession items */}
            {/* <Button variant="contained" color="primary" onClick={handleOpenConcessionItemModal}>
                Add Items
            </Button> */}
            {/* Concession item form modal */}
            {showConcessionItemModal && (
                <ConcessionItemForm open={showConcessionItemModal} onClose={handleCloseConcessionItemModal} onAdd={handleConcessionValueChange} />
            )}
            {/* Button to see bulk billing incentives */}
            {concessionValues.length > 0 && (
                <Button onClick={handleSeeBulkBillingIncentives} sx ={{
                    backgroundColor:"#56C596",
                    borderColor:'#56C596 !important',
                    textTransform: 'none', // Keeps text as provided
                    width: '10vw', // Set width
                    height: '5.1vh', // Set height
                    }}
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={20} />}
                    
                    > Next</Button>
            )}
        </Box>
    );
}

export default ConcessionItemsSection;
