import React, { useState, useEffect } from 'react';
import { Modal, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import './style.css';
import { registerUser } from '../../services/registerApi';



const RegisterModal = ({ open, handleClose }) => {
    const { isLoggedIn, login } = useAuth();
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        organisationName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false); // New state for snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for snackbar message

    useEffect(() => {
        if (isLoggedIn) {
            console.log("User logged in.");
        } else {
            console.log("User logged out.");
        }
    }, [isLoggedIn]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            // Handle form submission logic here
            // eslint-disable-next-line
            const registerResponse = await registerUser(formState)
            
            if (registerResponse.success) {
                handleClose()
                login();
                navigate('/home');
            } else {
                setSnackbarMessage(registerResponse.message);
                setSnackbarOpen(true);
            }
            
        } catch (error) {
            console.error(error.message);
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleModalClose = () => {
        // Reset the form state when the modal is closed
        setFormState({
            organisationName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        });
        handleClose();
    };
    return (
        <div>
        <Modal open={open} onClose={handleModalClose}>
         
                <RegisterForm
                    formState={formState}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                />
        </Modal>

<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
<Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
    {snackbarMessage}
</Alert>
</Snackbar>
</div>
    );
};

export default RegisterModal;
