import React from 'react';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Functional component for selecting billing type
const BillingTypeSelector = ({ setBillingType, setBillingTypeSelected }) => {
    const navigate = useNavigate();

    const handleBillingTypeChange = (event) => {
        if (event.target.value === 'notate') {
            navigate('/billing-notation');
        } else {
            
        setBillingType(event.target.value);
        setBillingTypeSelected(true);
        }

    };

    return (
        <FormControl component="fieldset" sx={{ textAlign: 'center' }}>
            {/* Label for the radio group */}
            <FormLabel component="legend" sx={{ fontSize: '35px', mb: 1, fontFamily: 'Manrope, sans-serif', color: 'black', '&.Mui-focused': {
                color: 'black' // Keeps the color black even when focused
            } }}>What would you like assistance with?</FormLabel>
            
            {/* Radio group for selecting billing type */}
            <RadioGroup aria-label="billing" name="radio-buttons-group" onChange={handleBillingTypeChange} sx={{ top: '2.5vh' }}>
                {/* Radio button for Bulk Billing */}
                <FormControlLabel value="bulk" control={<Radio sx={{ color: '#56C496' }} />} label="Bulk Billing Incentives" sx={{ '& .MuiFormControlLabel-label': { fontSize: '20px' } }} />
                {/* Radio button for Mixed Billing */}
                <FormControlLabel value="mixed" control={<Radio sx={{ color: '#56C496' }} />} label="Custom Billing Calculation" sx={{ '& .MuiFormControlLabel-label': { fontSize: '20px' } }} />
                {/* Radio button for Billing Notation */}
                <FormControlLabel value="notate" control={<Radio sx={{ color: '#56C496' }} />} label="Billing Notation" sx={{ '& .MuiFormControlLabel-label': { fontSize: '20px' } }} />
            </RadioGroup>
        </FormControl>
    );
}

export default BillingTypeSelector;
