import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, Button, Typography, Snackbar, Alert } from '@mui/material';
import { resetPassword } from '../services/resetUserPasswordApi'; // Adjust import path as necessary
import { useAuth } from '../Context/AuthContext'; // Adjust import path as necessary

export const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn } = useAuth();
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (isLoggedIn || !token) {
            navigate('/');
        }
    }, [isLoggedIn, token, navigate]);

    const passwordsMatch = password === confirmPassword && password !== '';
    const isFormValid = passwordsMatch && password.length > 0 && confirmPassword.length > 0;
    const isDisabled = !isFormValid || loading;

    const handleSnackbarClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSnackbarMessage('');
        setSnackbarOpen(false);
      
        if (!passwordsMatch) {
          setLoading(false);
          setSnackbarMessage("Passwords do not match.");
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }
      
        const result = await resetPassword(token, password);
        setLoading(false);
      
        setSnackbarMessage(result.message);
        setSnackbarSeverity(result.success ? 'success' : 'error');
        setSnackbarOpen(true);
      
        if (result.success) {
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      };
      

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            p: 1,
        }}>
            <Typography variant="h5" gutterBottom>Reset Your Password</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%', maxWidth: 360 }}>
                <TextField
                    label="New Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={loading}
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    disabled={loading}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isDisabled}
                >
                    Reset Password
                </Button>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};
