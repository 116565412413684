import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import GeneralButton from "../Components/General/Button";
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import RegisterModal from "../Components/RegisterModal/RegisterModal";
import LoginModal from "../Components/LoginModal";
import { useAuth } from '../Context/AuthContext'; // Adjust the import path as needed for useAuth
import Box from '@mui/material/Box';
import HomePageSVG from '../images/Landing Page.svg'
import { Button } from '@mui/base';

export const HomePage = () => {
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const navigate = useNavigate(); // Initialize useNavigate hook
    const { isLoggedIn } = useAuth(); // Access the isLoggedIn state from AuthContext

    // Redirects to /home if the user is logged in
    useEffect(() => {
        if (isLoggedIn) {
            navigate('/home');
        }
    }, [isLoggedIn, navigate]);

    const handleOpenRegisterModal = () => setIsRegisterModalOpen(true);
    const handleCloseRegisterModal = () => setIsRegisterModalOpen(false);

    const handleOpenLoginModal = () => setIsLoginModalOpen(true);
    const handleCloseLoginModal = () => setIsLoginModalOpen(false);
  

    return (
        <div>
            <Box
              sx={{
                position: 'absolute',
                display: 'grid',
                gap: '0px',
                width: { xs: '90vw', sm: '50vw', md: '25vw' },  // Gradually reduce width with screen size
                left: { xs: '5vw', sm: '10vw', md: '16.3vw' },  // Adjust horizontal position
                top: { xs: '30vh', sm: '34vh', md: '38.14vh' },  // Adjust vertical position
                gridTemplateColumns: { xs: '1fr', sm: 'min-content', md: 'max-content' },  // Adjust grid columns
            }}
            >
                {/* Medicare */}
                <Typography
                    component="h1"
                    sx={{
                        fontFamily: 'Manrope, sans-serif',

                        fontSize: '65px',
                        margin: 0,
                        textAlign: 'left',
                    }}
                >
                    The
                </Typography>
                {/* Billing Calculator, wrapped in a Box for additional control */}
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Typography
                        component="span" // Using span to keep inline with flexbox
                        sx={{
                            fontFamily: 'Manrope, sans-serif',

                            fontSize: '65px',
                            margin: 0,
                            textAlign: 'left',
                            whiteSpace: 'nowrap', // Ensures "Billing Calculator" stays on one line
                        }}
                    >

                        Billing Buddy
                    </Typography>
                </Box>

                <Grid container justifyContent="left" spacing={2}>
                    <Grid item>
                        <GeneralButton onClick={() => {navigate('/login')}} text={"Log in"} sx={{
                            backgroundColor: '#56C596', // Button Color
                            color: 'white', // Text Color
                            textTransform: 'none',
                            boxShadow: 'none',
                            width: '9.50vw', // Set width
                            height: '6.1vh', // Set height
                            '&:hover': {
                                backgroundColor: 'darken(#56C596, 0.2)', // Adjust for hover state
                            }
                        }} />
                    </Grid>
                    <Grid item>
                        <GeneralButton onClick={handleOpenRegisterModal} text={"Sign Up"} variant={"outlined"} sx={{
                            color: '#56C596', // Text and border color
                            borderColor: '#56C596 !important', // Explicit border color
                            textTransform: 'none', // Keeps text as provided
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            width: '9.50vw', // Set width
                            height: '6.1vh', // Set height
                            '&:hover': {
                                backgroundColor: '#56C596', // Fills the button on hover
                                color: 'white', // Changes text color to white on hover
                                borderColor: '#56C596', // Ensures border color remains consistent on hover
                            }
                        }} />
                    </Grid>
                </Grid>

            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    width: { xs: '80vw', md: '33.44vw' },
                    height: { xs: 'auto', md: '54.63vh' },
                    left: { xs: '10vw', md: '50%' },
                    top: { xs: '10vh', md: '22.69vh' },
                }}
            >
                <img src={HomePageSVG} alt="Your SVG Description" style={{ width: '100%', height: '100%' }} />
                {/* If SVG is in the public folder, use '/example.svg' in src */}
            </Box>




            {/* Register Modal */}
            <RegisterModal open={isRegisterModalOpen} handleClose={handleCloseRegisterModal} />

            {/* Login Modal */}
            <LoginModal open={isLoginModalOpen} handleClose={handleCloseLoginModal} />
        </div>
    );
}

export default HomePage;
