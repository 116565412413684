import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip, Modal, Box, Typography,
  TextField, Collapse
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GeneralButton from '../Components/General/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddBillingItem } from '../Components/AddBillingItemModal/AddBillingItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { addSpecialBillingCaseApi } from '../services/addSpecialBillingCaseApi';
import { fetchSpecialBillingData } from '../services/fetchSpecialBillingsDataApi';
import { EditSpecialBillingItemModal } from '../Components/AddBillingItemModal/EditSpecialBillingItemModal';
import { editSpecialBillingItemApi } from '../services/editSpecialBillingItemApi';
import { DeleteSpecialItemsModal } from '../Components/EditBillingPage/DeleteSpecialItemsModal/DeleteSpecialItemsModal';
import { deleteSpecialItem } from '../services/deleteSpecialItemApi';
import CustomSnackbar from '../Components/CustomSnackBar/CustomSnackBar';
import useSnackbar from '../hooks/useSnackBar';
import { useAuth } from '../Context/AuthContext';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const fabStyle = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
};

// Utility function to fetch billing data
const fetchBillingData = async (setBillingsData) => {
  try {
    const response = await fetch('/api/billing-info/billingItems', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("This is the billing data returned from the server!");
      console.log(data);

      // Update the billingsData state
      setBillingsData(data.billingItems);
    } else {
      console.error("HTTP error:", response.status, response.statusText);
    }
  } catch (error) {
    console.error("Network Error:", error.message);
  }
};

const EditBillings = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Add delete modal state
  const [newBilling, setNewBilling] = useState({ billingNumber: '', mbsRebate: '', privateFee: '' });
  const [billingsData, setBillingsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDropDown, setOpenDropDown] = useState({});
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState('');
  // const [snackbarSeverity, setSnackbarSeverity] = useState('success');

      // Redirects to /home if the user is logged in
  const { isAdmin } = useAuth()


  
  const [specialBillingData, setSpecialBillingData] = useState([]);
  
  const [openSpecialBillingModal, setOpenSpecialBillingModal] = useState(false);
  const [newSpecialBillingItem, setNewSpecialBillingItem] = useState({ billingNumber: '', billingCaseDescription: '', mbsRebate: '', privateFee: '' });
  

  const [openEditSpecialBillingItemModal, setOpenopenEditSpecialBillingItemModal] = useState(false);
  const [specialBillingItemToEdit, setSpecialBillingItemToEdit] = useState({ billingNumber: '', billingCaseDescription: '', mbsRebate: '', privateFee: '', concatName: '', itemId: '' });


  const [openDeleteSpecialBillingModal, setOpenDeleteSpecialBillingModal] = useState(false);
  const [specialBillingItemToDelete, setSpecialBillingItemToDelete] = useState({ billingNumberParent: '', itemId: '' });


      // Use the custom snackbar hook
      const {
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        handleSetSnackBar,
        handleCloseSnackBar,
    } = useSnackbar();



  const handleOpenDeleteSpecialBillingModal = (billingParent, specialBillingItemDetails) => {
    setSpecialBillingItemToDelete((prevState) => ({
      ...prevState, 
      itemId: specialBillingItemDetails.itemId, 
      billingNumberParent: billingParent
    }))
    setOpenDeleteSpecialBillingModal(true);
  }

  const handleCloseDeleteSpecialBillingModal = () => {

    setSpecialBillingItemToDelete((prevState) => ({
      ...prevState, 
      itemId: '', 
      billingNumberParent: ''
    }))


    setOpenDeleteSpecialBillingModal(false);
  } 

  const handleDeleteSpecialBillingItem = async (itemInfo) => {
    try {
      
      const deleteItemResult = await deleteSpecialItem(itemInfo);

      fetchSpecialBillingData(setSpecialBillingData);
      handleSetSnackBar("Special Item Deleted", "success", true); 

    
    } catch (error) {
      handleSetSnackBar("Delete Special Item Failed", "error", true); 
       
    
    } finally {

      
      handleCloseDeleteSpecialBillingModal();
    }
  }


  const handleOpenEditSpecialBillingModal = (billingParent, billingDetails) => {
    
    
    console.log(billingParent, billingDetails)

    setSpecialBillingItemToEdit((prevState) => ({
      ...prevState, 
      billingNumber: billingParent, 
      billingCaseDescription: billingDetails.billingCaseDescription, 
      mbsRebate: billingDetails.mbsRebate, 
      privateFee: billingDetails.privateFee, 
      concatName: billingDetails.concatName,
      itemId: billingDetails.itemId,
    }));


    setOpenopenEditSpecialBillingItemModal(true);


  }

  const handleCloseEditSpecialBillingItemModal = () => {
    
    setSpecialBillingItemToEdit((prevState) => ({
      ...prevState, 
      billingNumber: '', 
      billingCaseDescription: '', 
      mbsRebate: '', 
      privateFee: '', 
    }));


    setOpenopenEditSpecialBillingItemModal(false); 
  }

  const handleSaveSpecialItemEdit = async (newValue) => {

    try {
      
      const editResponse = await editSpecialBillingItemApi(newValue);
      console.log(editResponse)
      await fetchSpecialBillingData(setSpecialBillingData);

      // setSnackbarMessage("Item Edited");
      // setSnackbarSeverity('success');
      handleSetSnackBar("Item Edited", "success", true)
    
    } catch (error) {
      handleSetSnackBar("Edit Special Item Failed", "error", true)
      // setSnackbarMessage('Edit Special Item Failed');
      
      // setSnackbarSeverity('error'); 
    
    } finally {

      // setSnackbarOpen(true);
      handleCloseEditSpecialItemModal()
    }

  }

  const handleCloseEditSpecialItemModal = () => {

    setOpenopenEditSpecialBillingItemModal(false);
    setSpecialBillingItemToEdit(prevState => ({
      ...prevState,
      billingNumber: '', 
      billingCaseDescription: '', 
      mbsRebate: '', 
      privateFee: '',
      concatName: '', 
      itemId: '' 
    }))
  }


  const handleOpenSpecialBillingModal = (parentBillingNumber) => {
    
    
    // Update the value of the billing number for the 
    // parent billing number so we can splice it in the modal.
    setNewSpecialBillingItem(prevState => ({
      ...prevState,
      billingNumber: parentBillingNumber
    })); 

    setOpenSpecialBillingModal(true)
  
  };


  const handleCloseSpecialBillingModal = () => {

    setOpenSpecialBillingModal(false); 
    setNewSpecialBillingItem(prevState => ({
      ...prevState,
      billingNumber: '', 
      billingCaseDescription: '', 
      mbsRebate: '', 
      privateFee: '',
      concatName: '',

    })); 

  };
  
  const handleSaveSpecialBillingValues = async (newValues) => {

    try {
      setNewSpecialBillingItem(newValues);
      
      await addSpecialBillingCaseApi(newValues, handleUpdateAllbillings); 
      
      // setSnackbarMessage(`Special Case added for: ${newValues.billingNumber}`); 
      // setSnackbarSeverity('success');
      handleSetSnackBar(`Special Case added for: ${newValues.billingNumber}`,'success', true)

    } catch (error) {

      // console.log(error)
      // console.log(error)
      handleSetSnackBar(error.message,'error', true)
      // setSnackbarMessage(error.message)
      // setSnackbarSeverity('error')

    } finally {
      // setSnackbarOpen(true)
      handleCloseSpecialBillingModal();
    }
  } 

  // const handleCloseSnackbar = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setSnackbarOpen(false);
    
  //   setSnackbarMessage(''); // Reset message
  //   // setSnackbarSeverity('success'); // Reset severity
  // };



  // This is how we manage the drop down effect. 
  // Store each dropdown open var in an index and access it with the index of the row.
  const handleDropDownClick = async (index) => {
    
    setOpenDropDown((prev) => ({ ...prev, [index]: !prev[index] }));

  }

  // Gets billing data
  useEffect(() => {
    // Call the utility function to fetch billing data
    fetchBillingData(setBillingsData);
    fetchSpecialBillingData(setSpecialBillingData);
  }, []);

  useEffect(() => {
    console.log("Updated billingsData:", billingsData);
  }, [billingsData]);



  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleOpenEditModal = (row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (row) => {
    setSelectedRow(row);
    setOpenDeleteModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleUpdateAllbillings = () => {
    fetchBillingData(setBillingsData);
    fetchSpecialBillingData(setSpecialBillingData);
  }

  

  const handleAddItem = async () => {
   

    try {

      if (
        newBilling.billingNumber.trim() === '' ||
        newBilling.mbsRebate === '' ||
        newBilling.privateFee === ''
      ) {
        alert('Please fill in all fields.');
        return;
      }

      console.log(`New Item: ${newBilling.billingNumber}`);
      
      if (billingsData.find(obj => obj.billingNumber === newBilling.billingNumber)) {
        handleSetSnackBar('Item Already Exists', 'error', true);
        return;
      }

      const response = await fetch('/api/billing-info/add-billingItems', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBilling),
      });

      if (response.ok) {
        const data = await response.json();
        // Handle success: data.newItem contains the newly added item
        console.log('Item added:', data.newItem);
        handleSetSnackBar('Item Added Succesfully', 'success', true);
        // Update your client-side state or perform any necessary actions
        fetchBillingData(setBillingsData);
      } else {
        console.error('HTTP error:', response.status, response.statusText);
        // Handle error response
      }
    } catch (error) {
      console.error('Network Error:', error.message);
      // Handle network error
    } finally {
       // Clear input fields and close the modal
    setNewBilling({ billingNumber: '', mbsRebate: '', privateFee: '' });
    handleCloseAddModal();
    }

   

  };



  const handleEditItem = async () => {
    if (!selectedRow) {
      return;
    }
    try {
      const response = await fetch(`/api/billing-info/update-billingItem/${selectedRow.billingNumber}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedRow),
      });

      if (response.ok) {
        // Handle the response, update the state, etc.
        console.log("WHOOOO")
        fetchBillingData(setBillingsData);
      } else {
        console.error('HTTP error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Network Error:', error.message);
    }

    handleCloseEditModal();
  };


  const handleDeleteItem = async () => {
    if (!selectedRow) {
      return;
    }

    try {

      let encodedBillingNumber = encodeURIComponent(selectedRow.billingNumber)
      // Replace '/api/billing-info/delete-billingItem' with your actual API endpoint
      // and ensure it can handle a DELETE request with a billingNumber parameter.
      const response = await fetch(`/api/billing-info/delete-billingItem/${encodedBillingNumber}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('Item deleted successfully');
        // Call fetchBillingData to refresh the list after deletion
        fetchBillingData(setBillingsData);
      } else {
        console.error('HTTP error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Network Error:', error.message);
    }

    // Close the delete modal
    handleCloseDeleteModal();
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const calculateGap = (mbsRebate, privateFee) => {
    return (privateFee - mbsRebate).toFixed(2);
  };

  const filteredData = billingsData.filter((item) =>
    item && item.billingNumber && typeof item.billingNumber === 'string'
      ? item.billingNumber.toLowerCase().includes(searchQuery.toLowerCase())
      : false
  );
  return (
    <>
      <Typography variant="h3" component="h1" style={{ color: 'black', marginBottom: '50px', textAlign: 'center' }}>
        Billing Settings
      </Typography>


      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            left: '11vw',
            mb: 2, // Adds a little space below the label
            color: 'text.secondary',
            position: 'relative'
          }}
        >
          Search
        </Typography>
        <TextField
          placeholder="Billing number"
          inputProps={{ 'aria-label': 'search billing number' }}
          sx={{
            input: {
              color: 'black', backgroundColor: '#F1FAF7', fontWeight: 800, borderRadius: '10px', "&::placeholder": {
                color: '#96D8BC'
              }
            }, width: '80%', backgroundColor: '#F1FAF7', borderRadius: '10px', maxWidth: 300, margin: 'auto', left: '11vw'
          }}
          value={searchQuery}
          onChange={handleSearchChange}
        />

        <GeneralButton onClick={handleOpenAddModal} text={'Add'} variant={"outlined"}   disabled={!isAdmin} sx={{
          color: '#56C596', // Text and border color
          borderColor: '#56C596 !important', // Explicit border color
          textTransform: 'none', // Keeps text as provided
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '15.62vw',
          left: '53vw',
          mt: '10px',


          '&:hover': {
            backgroundColor: '#56C596', // Fills the button on hover
            color: 'white', // Changes text color to white on hover
            borderColor: '#56C596', // Ensures border color remains consistent on hover
          }
        }} />

      </Box>
      {/* 
        <Tooltip title="Add New Item">
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleOpenAddModal}
            sx={fabStyle}
          >
            <AddIcon />
          </Fab>
        </Tooltip> */}

      <Box sx={{ position: 'relative', width: '80%', margin: 'auto', overflowX: 'auto' }}>
        <Table sx={{ backgroundColor: 'white', mt: '25px', borderRadius: '10px' }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{ color: 'grey' }}>Billing Number</TableCell>
              <TableCell sx={{ color: 'grey' }}>MBS Rebate</TableCell>
              <TableCell sx={{ color: 'grey' }}>Private Fee</TableCell>
              <TableCell sx={{ color: 'grey' }}>Gap</TableCell>
              <TableCell sx={{ color: 'grey' }}>Edit</TableCell>
              <TableCell sx={{ color: 'grey' }}>Delete</TableCell> {/* Add delete column */}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: 'white', borderColor: 'red', borderWidth: '2rem' }}>
            {filteredData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleDropDownClick(index)}
                    >
                      {openDropDown[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.billingNumber}</TableCell>
                  <TableCell>${parseFloat(row.mbsRebate).toFixed(2)}</TableCell>
                  <TableCell>${parseFloat(row.privateFee).toFixed(2)}</TableCell>
                  <TableCell>${calculateGap(parseFloat(row.mbsRebate), parseFloat(row.privateFee))}</TableCell>
                  
                  <TableCell>
                    <Tooltip title={ 'Edit'}>
                      <IconButton onClick={() => isAdmin && handleOpenEditModal(row)}
                      disabled={!isAdmin}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => isAdmin && handleOpenDeleteModal(row)} disabled={!isAdmin}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={openDropDown[index]} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {`Special Cases for item '${row.billingNumber}'`}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'grey' }}> Description</TableCell>
                    <TableCell sx={{ color: 'grey' }}>MBS Rebate</TableCell>
                    <TableCell sx={{ color: 'grey' }}>Private Fee</TableCell>
                    <TableCell sx={{ color: 'grey' }}>Gap</TableCell>
                    <TableCell sx={{ color: 'grey' }}>Edit</TableCell>
                    <TableCell sx={{ color: 'grey' }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specialBillingData.map((specialItem, specialIndex) => {
                    const billingKey = Object.keys(specialItem)[0];
                    const billingDetails = specialItem[billingKey];

                    // Check if the current special item matches the billing number of the row
                    if (billingKey === row.billingNumber) {
                      return (
                        <TableRow key={specialIndex}>
                          <TableCell>{billingDetails.billingCaseDescription}</TableCell>
                          <TableCell>${billingDetails.mbsRebate.toFixed(2)}</TableCell>
                          <TableCell>${billingDetails.privateFee.toFixed(2)}</TableCell>
                          <TableCell>${calculateGap(billingDetails.mbsRebate, billingDetails.privateFee)}</TableCell>
                          <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleOpenEditSpecialBillingModal(row.billingNumber, billingDetails) && isAdmin} disabled={!isAdmin}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleOpenDeleteSpecialBillingModal(row.billingNumber, billingDetails) && isAdmin} disabled={!isAdmin}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center',     // Center vertically
                            // Full viewport height
                            padding: '15px',
                          }}
                        >
                          <Tooltip title={"Add Special Case"}>
                            <IconButton onClick={() => handleOpenSpecialBillingModal(row.billingNumber) && isAdmin} disabled={!isAdmin}>
                              <AddCircleIcon sx={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}



          </TableBody>
        </Table>
      </Box>


      <Modal
  open={openAddModal}
  onClose={handleCloseAddModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Add New Item
    </Typography>
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        margin="normal"
        label="Billing Number"
        value={newBilling.billingNumber}
        onChange={(e) => setNewBilling({ ...newBilling, billingNumber: e.target.value})}
      />
      <TextField
        fullWidth
        margin="normal"
        label="MBS Rebate"
        type="number"
        value={newBilling.mbsRebate}
        onChange={(e) => setNewBilling({ ...newBilling, mbsRebate: e.target.value })}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Private Fee"
        type="number"
        value={newBilling.privateFee}
        onChange={(e) => setNewBilling({ ...newBilling, privateFee: e.target.value })}
      />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <GeneralButton text={'Add'} onClick={handleAddItem} sx={{
        backgroundColor: "#56C596",
        borderColor: '#56C596 !important',
        textTransform: 'none',
        width: '45%',
        marginRight: '10px'
      }} />
      <GeneralButton onClick={handleCloseAddModal} text={'Cancel'} variant={"outlined"} sx={{
        color: '#56C596',
        borderColor: '#56C596 !important',
        textTransform: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '45%',
        '&:hover': {
          backgroundColor: '#56C596',
          color: 'white',
          borderColor: '#56C596',
        }
      }} />
    </Box>
  </Box>
</Modal>


      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" >
            Edit Item
          </Typography>
          {selectedRow && (
            <Box component="form" noValidate autoComplete="off">
               <Tooltip title="This field cannot be edited">
              <TextField
                fullWidth
                margin="normal"
                label="Billing Number"
                value={selectedRow.billingNumber}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#f0f0f0', // Light grey background
                    cursor: 'not-allowed', // Cursor to indicate non-editable
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#d0d0d0', // Light grey border
                  },
                }}
              />
              </Tooltip>
              <TextField
                fullWidth
                margin="normal"
                label="MBS Rebate"
                type="number"
                value={selectedRow.mbsRebate}
                onChange={(e) => setSelectedRow({ ...selectedRow, mbsRebate: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Private Fee"
                type="number"
                value={selectedRow.privateFee}
                onChange={(e) => setSelectedRow({ ...selectedRow, privateFee: e.target.value })}
              />
              <GeneralButton onClick={handleEditItem} text={'Save'} sx={{
                backgroundColor: "#56C596",
                borderColor: '#56C596 !important',
                textTransform: 'none', // Keeps text as provided
                width: '4.50vw',
                marginRight: '10px'
              }}>Add </GeneralButton>
                  <GeneralButton onClick={handleCloseEditModal} text={'Cancel'} variant={"outlined"} sx={{
              color: '#56C596', // Text and border color
              borderColor: '#56C596 !important', // Explicit border color
              textTransform: 'none', // Keeps text as provided
              backgroundColor: 'transparent',
              boxShadow: 'none',
              width: '4.50vw',
              '&:hover': {
                backgroundColor: '#56C596', // Fills the button on hover
                color: 'white', // Changes text color to white on hover
                borderColor: '#56C596', // Ensures border color remains consistent on hover
              }
            }} />
            </Box>
          )}
        </Box>
      </Modal>

      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Item
          </Typography>
          {selectedRow && (
            <Typography variant="body1" gutterBottom>
              Are you sure you want to delete this item?
            </Typography>
          )}
          <Box sx={{ mx: 'auto' }}>

            <GeneralButton onClick={handleDeleteItem} text={'Delete'} sx={{
              backgroundColor: "#56C596",
              borderColor: '#56C596 !important',
              textTransform: 'none', // Keeps text as provided
              width: '4.50vw',
              marginRight: '10px'
            }} />
            <GeneralButton onClick={handleCloseDeleteModal} text={'Cancel'} variant={"outlined"} sx={{
              color: '#56C596', // Text and border color
              borderColor: '#56C596 !important', // Explicit border color
              textTransform: 'none', // Keeps text as provided
              backgroundColor: 'transparent',
              boxShadow: 'none',
              width: '4.50vw',
              '&:hover': {
                backgroundColor: '#56C596', // Fills the button on hover
                color: 'white', // Changes text color to white on hover
                borderColor: '#56C596', // Ensures border color remains consistent on hover
              }
            }} />
          </Box>
        </Box>
      </Modal>
      <AddBillingItem openSpecialBillingModal={openSpecialBillingModal} newSpecialBillingItem={newSpecialBillingItem} handleCloseSpecialBillingModal={handleCloseSpecialBillingModal} handleSaveSpecialBillingValues={handleSaveSpecialBillingValues} />
      <EditSpecialBillingItemModal open={openEditSpecialBillingItemModal} itemToEdit={specialBillingItemToEdit} handleClose={handleCloseEditSpecialBillingItemModal} handleSaveNewValues={handleSaveSpecialItemEdit}/>
      <DeleteSpecialItemsModal open={openDeleteSpecialBillingModal} handleDelete={handleDeleteSpecialBillingItem} handleClose={handleCloseDeleteSpecialBillingModal} itemInformation={specialBillingItemToDelete} />
      
        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar> */}
        <CustomSnackbar open={snackbarOpen} message={snackbarMessage} severity={snackbarSeverity} onClose={handleCloseSnackBar} />
    
    
    </>
  );
};

export default EditBillings;
