import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const EmailInput = ({ placeholder, name, value, onChange }) => {
  return (
    <TextField
      placeholder={placeholder}
      fullWidth
      margin="normal"
      name={name}
      value={value}
      onChange={onChange}
      type="email"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EmailIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        borderRadius: '10px',
        backgroundColor: '#F1FAF7',
      }}
    />
  );
};

const OrganizationInput = ({ placeholder, name, value, onChange }) => {
  return (
    <TextField
      placeholder={placeholder}
      fullWidth
      margin="normal"
      name={name}
      value={value}
      onChange={onChange}
      type="text"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <HomeIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        borderRadius: '10px',
        backgroundColor: '#F1FAF7',
      }}
    />
  );
};

const NameInput = ({ placeholder, name, value, onChange }) => {
  return (
    <TextField
      placeholder={placeholder}
      fullWidth
      margin="normal"
      name={name}
      value={value}
      onChange={onChange}
      type="text"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PersonIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        borderRadius: '10px',
        backgroundColor: '#F1FAF7',
      }}
    />
  );
};

const PasswordInput = ({ placeholder, name, value, onChange }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <TextField
      placeholder={placeholder}
      fullWidth
      margin="normal"
      name={name}
      value={value}
      onChange={onChange}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        borderRadius: '10px',
        backgroundColor: '#F1FAF7',
      }}
    />
  );
};

export { EmailInput, OrganizationInput, NameInput, PasswordInput };
