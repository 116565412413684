import React, { useState } from 'react';
import { TextField, Button, DialogContent, DialogActions, Dialog, DialogTitle } from '@mui/material';
import GeneralButton from '../General/Button';

const ItemForm = ({ onAdd, onClose, onOpen }) => {
  const [itemNumber, setItemNumber] = useState('');
  const [mbsRebate, setMbsRebate] = useState('');
  const [privateFee, setPrivateFee] = useState('');
  const [error, setError] = useState('');

  const calculateGAP = (mbsRebate, privateFee) => {
    return Number(privateFee) - Number(mbsRebate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation: Check if any field is empty
    if (!itemNumber.trim() || !mbsRebate.trim() || !privateFee.trim()) {
      setError('Please fill in all fields');
      return; // Prevent submission
    }

    // Check if MBS Rebate and Private Fee are integers
    if (!Number.isInteger(Number(mbsRebate)) || !Number.isInteger(Number(privateFee))) {
      setError('MBS Rebate and Private Fee must be integers');
      return; // Prevent submission
    }

    // Calculate GAP
    const gap = calculateGAP(mbsRebate, privateFee);
    console.log('itemNumber:', itemNumber, typeof itemNumber);
    console.log('mbsRebate:', mbsRebate, typeof mbsRebate);
    console.log('privateFee:', privateFee, typeof privateFee);
    console.log('gap:', gap, typeof gap);

    onAdd({ billingNumber: itemNumber, 'mbsRebate': Number(mbsRebate), 'privateFee': Number(privateFee), GAP: gap });
    setItemNumber('');
    setMbsRebate('');
    setPrivateFee('');
    setError(''); // Clear any previous error
    onClose(); // Close the modal after submission
  };

  return (
    <Dialog open={onOpen} onClose={onClose} sx={{ maxWidth: 400, mx: 'auto' }}>
      <DialogTitle>Add item number</DialogTitle>
      <DialogContent>
        {error && <p style={{ color: 'red', marginBottom: '15px' }}>{error}</p>}
        <TextField
          label="Item Number"
          value={itemNumber}
          onChange={(e) => setItemNumber(e.target.value)}
          sx={{ margin: '10px 0' }} // Add margin between input fields
          fullWidth
        />
        <TextField
          label="MBS Rebate"
          value={mbsRebate}
          onChange={(e) => setMbsRebate(e.target.value)}
          type="number"
          sx={{ margin: '10px 0' }}
          fullWidth
        />
        <TextField
          label="Private Fee"
          value={privateFee}
          onChange={(e) => setPrivateFee(e.target.value)}
          type="number"
          sx={{ margin: '10px 0' }}
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ mx: 'auto' }}>
        <GeneralButton sx={{
          backgroundColor: "#56C596",
          borderColor: '#56C596 !important',
          textTransform: 'none', // Keeps text as provided
          width: '4.50vw',
        }} text={"Add"} onClick={handleSubmit} variant="contained" color="primary">Add Item</GeneralButton>
        <GeneralButton sx={{
          color: '#56C596', // Text and border color
          borderColor: '#56C596 !important', // Explicit border color
          textTransform: 'none', // Keeps text as provided
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '4.50vw',
          '&:hover': {
            backgroundColor: '#56C596', // Fills the button on hover
            color: 'white', // Changes text color to white on hover
            borderColor: '#56C596', // Ensures border color remains consistent on hover
          }
        }} text={"Cancel"} onClick={onClose} variant={"outlined"}>Cancel</GeneralButton>

      </DialogActions>
    </Dialog>
  );
};

export default ItemForm;
