import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/system/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import {useAuth} from '../../Context/AuthContext'; 

const AppMenu = () => {

  const navigate = useNavigate(); 
  const { logout, isAdmin } = useAuth();


  useEffect(() => {

  }, [isAdmin]); 
  


  const handleLogout = async () => {

    console.log("Logging out")

    try {
      const response = await fetch('/api/user/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        logout();
        navigate('/');
      } else {
        console.error("HTTP error:", response.status, response.statusText);
      }

    } catch (error) {

    }

  };

  
  // Dynamically generate profile submenu based on isAdmin status
  const getProfileSubmenu = () => {
    if (isAdmin) {
      return [
        { label: 'Manage Users', action: () => navigate('/manage-users') },
        { label: 'My Details', action: () => navigate('/manage-account') },
        { label: 'Delete Account', action: () => navigate('/admin-delete-account') },
        { label: 'Logout', action: handleLogout },
      ];
    } else {
      return [
        { label: 'My Details', action: () => navigate('/manage-account') },
        { label: 'Logout', action: handleLogout },
      ];
    }
  };

  const menuItems = [
    // Existing menu items
    { label: 'Home', action: () => navigate('/home') },
    {
      label: 'Billing Calculator',
      submenu: [

        {label: 'Access Billing Calculator', action: () => navigate('/calculate')},
        {label: 'Access Billing Notation', action: () => navigate('/billing-notation')},
        { label: 'Edit Billing Information', action: () => navigate('/edit-billings')},
        { label: 'Edit Bulk Billing Incentive Information', action: () => navigate('/edit-concession-billings') },
        { label: 'Edit Notation Settings', action: () => navigate('edit-notation')}
      ]
    },
    {
      label: 'Support',
      submenu: [
        { label: 'Walkthrough', action: () => navigate('/walkthrough') },
        { label: 'FAQ', action: () => navigate('/faqs') },
        { label: 'Contact Us (TBA)', action: () => console.log("Contact Us") }
      ]
    },
    {
      label: 'Profile',  // Assuming you want dropdown for profile too
      submenu: getProfileSubmenu(),
    }
  ];

  const [menuAnchorEl, setMenuAnchorEl] = useState({});

  const handleMenuClick = (event, index) => {
    setMenuAnchorEl({ ...menuAnchorEl, [index]: event.currentTarget });
    
  };
  const handleMenuClose = (index) => {
    setMenuAnchorEl({ ...menuAnchorEl, [index]: null });
  };

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent', mb: '20px' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          {menuItems.map((item, index) => (
            item.label !== 'Profile' ? <div key={index}>
              <Button
                sx={{
                  marginRight: 2, backgroundColor: 'transparent',
                  color: 'black', // Text color
                  fontFamily: 'Manrope, sans-serif',
                  'font-weight': '600',
      
                  '&:hover': {
                    backgroundColor: 'transparent', // Keeps background transparent on hover
                  },
                  '&:focus': {
                    backgroundColor: 'transparent', // Keeps background transparent on focus
                  },
                  '&:active': {
                    backgroundColor: 'transparent', // Keeps background transparent on active/click
                  }
      
                }}
                aria-haspopup="true"
                onClick={item.submenu ? (event) => handleMenuClick(event, index) : item.action}
              >
                {item.label}
                {item.submenu && <ArrowDropDownIcon />}
              </Button>
              {item.submenu && (
                <Menu
                  id={`menu-${index}`}
                  anchorEl={menuAnchorEl[index]}
                  open={Boolean(menuAnchorEl[index])}
                  onClose={() => handleMenuClose(index)}
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <MenuItem key={subIndex} onClick={()=>{subItem.action(); handleMenuClose(index)}}>
                      {subItem.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div> : null
          ))}
        </Box>
        <Box>
          {/* Profile dropdown aligned to the far right */}
          <Button
             sx={{
              marginRight: 2, backgroundColor: 'transparent',
              color: 'black', // Text color
              fontFamily: 'Manrope, sans-serif',
              'font-weight': '600',
              '&:hover': {
                backgroundColor: 'transparent', // Keeps background transparent on hover
              },
              '&:focus': {
                backgroundColor: 'transparent', // Keeps background transparent on focus
              },
              '&:active': {
                backgroundColor: 'transparent', // Keeps background transparent on active/click
              }
  
            }}
            aria-haspopup="true"
            onClick={(event) => handleMenuClick(event, menuItems.findIndex(item => item.label === 'Profile'))}
          >
            Profile <ArrowDropDownIcon />
          </Button>
          <Menu
            id="menu-profile"
            anchorEl={menuAnchorEl[menuItems.findIndex(item => item.label === 'Profile')]}
            open={Boolean(menuAnchorEl[menuItems.findIndex(item => item.label === 'Profile')])}
            onClose={() => handleMenuClose(menuItems.findIndex(item => item.label === 'Profile'))}
          >
            {menuItems.find(item => item.label === 'Profile').submenu.map((subItem, subIndex) => (
              <MenuItem key={subIndex} onClick={()=>{subItem.action();  handleMenuClose(menuItems.findIndex(item => item.label === 'Profile'))}}>
                {subItem.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppMenu;






// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { Drawer, List, ListItemButton, ListItemText, Collapse, IconButton } from '@mui/material';
// import { ExpandLess, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
// // eslint-disable-next-line
// import { useAuth } from '../../Context/AuthContext';

// // Component for each item in the sidebar
// const SideBarItem = ({ label, items, depthStep = 5, depth = 1, link, action }) => {
  
  
//   const [open, setOpen] = useState(false);

//   const handleClick = () => setOpen(!open);
//   const isLink = !!link;

//   if (action) {
//     return (
//       <ListItemButton onClick={action} sx={{ paddingLeft: depth * depthStep }}>
//         <ListItemText primary={label} />
//       </ListItemButton>
//     );
//   }

//   return (
//     <>
//       {isLink ? (
//         <ListItemButton component={Link} to={link} sx={{ paddingLeft: depth * depthStep }}>
//           <ListItemText primary={label} />
//         </ListItemButton>
//       ) : (
//         <ListItemButton onClick={items ? handleClick : null} sx={{ paddingLeft: depth * depthStep }}>
//           <ListItemText primary={label} />
//           {items && (open ? <ExpandLess /> : <ExpandMore />)}
//         </ListItemButton>
//       )}

//       {Array.isArray(items) && (
//         <Collapse in={open} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             {items.map((subItem, index) => (
//               <SideBarItem key={`${subItem.label}-${index}`} depth={depth + 1} depthStep={depthStep} {...subItem} />
//             ))}
//           </List>
//         </Collapse>
//       )}
//     </>
//   );
// };

// // Main SideBar component
// const SideBar = () => {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const { logout, isAdmin } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     // This code runs if `dependency1` or `dependency2` changes.
//   }, [isAdmin]);
  

//   const handleLogout = async () => {


//     try {
//       const response = await fetch('/api/user/logout', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },

//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log(data);
//         logout();
//         navigate('/');
//       } else {
//         console.error("HTTP error:", response.status, response.statusText);
//       }

//     } catch (error) {

//     }

//   };

//   const toggleDrawer = () => {
//     setIsDrawerOpen(!isDrawerOpen);
//   };

//   const adminItems = [
//     { label: 'Manage Users', link: '/manage-users' },
//     { label: 'Delete Account', link: '/admin-delete-account' },
//   ];

//   const nonAdminItems = [
//     { label: 'Manage My Account', link: '/manage-account' },
//   ];


//   const items = [
//     {
//       label: 'Home',
//       link: '/home',
//     },
//     {
//       label: 'Billing Calculator',
//       items: [
//         { label: 'Calculate', link: '/calculate' },
//         { label: 'Edit Billing Settings', link: '/edit-billings' },
//         { label: 'Edit Concession Billing Settings', link: '/edit-concession-billings' },
//       ],
//     },
//     {
//       label: 'Help',
//       items: [
//         { label: 'Walkthrough', link: '/walkthrough' },
//         { label: 'FAQs', link: '/faqs' },
//       ],
//     },
//     {
//       label: 'Logout',
//       action: handleLogout,
//     },
//   ];
//   console.log("In is admin", isAdmin)
//   if (isAdmin) {
//     items.splice(items.length - 1, 0, ...[
//       {
//         label: 'Settings',
//         items: adminItems,
//       },
//     ]);
//   } else if (!isAdmin) {
//     items.splice(items.length - 1, 0, ...[
//       {
//         label: 'Settings',
//         items: nonAdminItems,
//       },
//     ]);
//   }

//   return (
//     <>
//       <IconButton onClick={toggleDrawer}>
//         <MenuIcon />
//       </IconButton>
//       <Drawer variant="temporary" open={isDrawerOpen} onClose={toggleDrawer}>
//         <List>
//           {items.map((item, index) => (
//             <SideBarItem key={`${item.label}-${index}`} {...item} />
//           ))}
//         </List>
//       </Drawer>
//     </>
//   );
// };

// export default SideBar;
