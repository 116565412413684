import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function AutoCompleteComponent(props) {
  const [billingData, setBillingData] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');  // Add state for input value

  // Utility function to fetch billing data
  const fetchBillingData = async () => {
    try {
      const response = await fetch('/api/billing-info/billing-items-auto-complete', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("This is the billing data returned from the server!");
        console.log(data);

        // Update the billingData state
        setBillingData(data.billingItems);
      } else {
        console.error("HTTP error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };

  // Fetch billing data on component mount
  useEffect(() => {
    fetchBillingData();
  }, []);

  // Assuming each billing item has a 'name' or similar property to display
  const options = billingData.map((item) => item.billingNumber);

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}  // Set the input value
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);  // Update the input value state
      }}
      // disableClearable
      forcePopupIcon={false}
      freeSolo
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: '15.62vw', backgroundColor: '#F1FAF7', borderRadius: '10px', maxWidth: 300, mx: 'auto', marginBottom: '30px' }}
      renderInput={(params) => (
        <TextField
          sx={{
            input: {
              color: 'black',
              backgroundColor: '#F1FAF7',
              fontWeight: 800,
              "&::placeholder": {
                color: '#96D8BC'
              }
            }
          }}
          {...params}
          placeholder="Item number"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Item not showing?">
                  <IconButton onClick={() => { props.openModal(true) }} size="small">
                    <AddCircleOutlineIcon sx={{ color: '#56C596' }} /> {/* This adds the clickable plus icon */}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setValue(newValue);  // Set the value state
          props.setValue(billingData.find(obj => obj.billingNumber === newValue));
          // setInputValue('');  // Clear the input value to force recognition of the same item
          setValue(null)
        }
      }}
    />
  );
}
