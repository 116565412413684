import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GeneralButton from './Button';

/*
Certainly! Let's break down the changes made to the `CustomModal` component step by step:

1. **Dynamically Determine Small Screen Size**:
   ```javascript
   const isSmallScreen = window.innerWidth <= 600;
   ```
   Here, I'm using `window.innerWidth` to get the width of the viewport. I set a breakpoint at 600 pixels, considering screens narrower than this as small screens. This variable `isSmallScreen` will be used to dynamically adjust the dialog's behavior based on the screen size.

2. **Adjust Dialog Width**:
   ```javascript
   fullWidth maxWidth="md"
   ```
   I've set the `fullWidth` prop to ensure that the dialog takes up the entire width of its container. The `maxWidth` is set to "md" (medium), but you can adjust it as needed based on your design requirements.

3. **Adjust Dialog Content Height**:
   ```javascript
   <DialogContent sx={{ minHeight: 'auto', maxHeight: isSmallScreen ? '50vh' : '70vh', overflowY: 'auto' }}>
   ```
   In the `DialogContent`, I've used the `sx` prop, which allows for custom styling using the Material-UI styling solution. I set `minHeight` to 'auto' to let the content determine its own minimum height. For `maxHeight`, I set it to '50vh' (50% of the viewport height) for small screens and '70vh' (70% of the viewport height) for larger screens. This prevents the content from overflowing the dialog while still allowing it to scroll if needed (`overflowY: 'auto'`).

4. **Adjust Button Styles**:
   ```javascript
   <GeneralButton text={buttonLabel} onClick={onButtonClick} color="primary" sx={{
       minWidth: isSmallScreen ? 'auto' : '150px',
       height: 'auto',
       ...
   }} />
   ```
   In the button components, I adjusted the `minWidth` to 'auto' for small screens and '150px' for larger screens. This ensures that the buttons don't get squashed on small screens while maintaining a reasonable size on larger screens. I also set `height` to 'auto' to allow the button height to adjust based on its content.

These adjustments ensure that the dialog and its content are responsive to different screen sizes, preventing content overflow and maintaining a visually appealing layout across various devices. Adjust the specific values according to your design requirements and preferences.
*/


function CustomModal({ isOpen, handleClose, title, message, buttonLabel, onButtonClick }) {

    const isSmallScreen = window.innerWidth <= 600; // Define breakpoint for small screen

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md"> {/* Adjust maxWidth as needed */}
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ minHeight: 'auto', maxHeight: isSmallScreen ? '50vh' : '70vh', overflowY: 'auto' }}> {/* Adjust minHeight and maxHeight */}
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <GeneralButton text={buttonLabel} onClick={onButtonClick} color="primary" sx={{
                    backgroundColor: '#56C596',
                    color: 'white',
                    textTransform: 'none',
                    boxShadow: 'none',
                    minWidth: isSmallScreen ? 'auto' : '150px', // Adjust minWidth
                    height: 'auto', // Adjust height to auto
                    '&:hover': {
                        backgroundColor: 'darken(#56C596, 0.2)',
                    }
                }} />
                <GeneralButton onClick={handleClose} text={"Close"} variant={"outlined"} sx={{
                    color: '#56C596',
                    borderColor: '#56C596 !important',
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    minWidth: isSmallScreen ? 'auto' : '150px', // Adjust minWidth
                    height: 'auto', // Adjust height to auto
                    '&:hover': {
                        backgroundColor: '#56C596',
                        color: 'white',
                        borderColor: '#56C596',
                    }
                }} />
            </DialogActions>
        </Dialog>
    );
}

export default CustomModal;
