import Typography from '@mui/material/Typography';

export const WalkThrough = () => {


    return (
        <Typography variant="h3" component="h1" style={{ color: '#333', margin: '20px 0', textAlign: 'center'}}>
        Welcome to your edit user Walk Through page
    </Typography>
    )
}

export default WalkThrough;