import {
    Typography, Box, TextField, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Card, CardContent, Tooltip, IconButton
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import GeneralButton from '../Components/General/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const Notate = () => {
    const [billingsData, setBillingsData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [showResults, setShowResults] = useState(false);


    // Function to copy content to the clipboard
    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            console.log(`Copied to clipboard: ${content}`);
        }).catch(err => {
            console.error(`Could not copy: ${err}`);
        });
    };

    // Handle search input changes
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Split the search query into terms
    const searchTerms = searchQuery.split(/[, ]+/).filter(Boolean);
    const filteredData = billingsData.filter((item) =>
        searchTerms.every((term) =>
            item.billingCombination.some((value) =>
                value.toLowerCase().includes(term.toLowerCase())
            )
        )
    );

    // Toggle the selection of an item
    const handleSelectItem = (index) => {
        setSelectedIndices(prevSelected => {
            if (prevSelected.includes(index)) {
                return prevSelected.filter(i => i !== index); // Deselect
            } else {
                return [...prevSelected, index]; // Select
            }
        });
    };

    // Fetch billing data on component mount
    useEffect(() => {
        fetchBillingData();
    }, []);

    // Fetch billing data function
    const fetchBillingData = async () => {
        try {
            const response = await fetch('/api/billing-notation/getBillingNotationItems');
            if (response.ok) {
                const data = await response.json();
                setBillingsData(data.billingNotationItems);
            } else {
                console.error("HTTP error:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Network Error:", error.message);
        }
    };

    // Switch to the results view
    const handleSeeResults = () => {
        setShowResults(true);
    };

    // Go back to the selection table view
    const handleBackToSelection = () => {
        setShowResults(false);
    };

    // Reset the selection
    const handleResetSelection = () => {
        setSelectedIndices([]);
    };

    // Display cards for selected items
    const selectedItems = selectedIndices.map(index => filteredData[index]);

    return (
        <>
            <Typography variant="h3" component="h1" style={{ color: 'black', marginBottom: '50px', textAlign: 'center' }}>
                Notate
            </Typography>

            {!showResults && (
                <Box sx={{ position: 'relative', width: '80%', margin: 'auto', display: 'flex', justifyContent: 'space-between', mb: '20px' }}>
                    {/* Search Bar (left) */}
                    <TextField
                        placeholder="Search by billing combination"
                        inputProps={{ 'aria-label': 'search billing combination' }}
                        sx={{
                            input: {
                                color: 'black',
                                backgroundColor: '#F1FAF7',
                                fontWeight: 800,
                                borderRadius: '10px',
                                "&::placeholder": {
                                    color: '#96D8BC'
                                }
                            },
                            width: 300,
                            borderRadius: '10px'
                        }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />

                    {/* Reset/Results Buttons (right) */}
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <GeneralButton onClick={handleResetSelection} text={'Reset Selection'}  sx={{
                            color: 'white',
                            borderColor: '#56C596 !important',
                            textTransform: 'none',
                            backgroundColor: '#56C596', // Button Color
                            boxShadow: 'none',
                            width: '15.62vw',
                            '&:hover': {
                                backgroundColor: '#56C596',
                                color: 'white',
                                borderColor: '#56C596'
                            }
                        }} />
                        <GeneralButton onClick={handleSeeResults} text={'See Results'} variant={"outlined"} sx={{
                            color: '#56C596',
                            borderColor: '#56C596 !important',
                            textTransform: 'none',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            width: '15.62vw',
                            '&:hover': {
                                backgroundColor: '#56C596',
                                color: 'white',
                                borderColor: '#56C596'
                            }
                        }} />
                    </Box>
                </Box>
            )}

            {!showResults && (
                <Box sx={{ position: 'relative', width: '80%', margin: 'auto', overflowX: 'auto' }}>
                    <Table sx={{ backgroundColor: 'white', mt: '25px', borderRadius: '10px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Select</TableCell>
                                <TableCell>Billing Combination</TableCell>
                                <TableCell>Billing Notation</TableCell>
                                <TableCell>Billing Property</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedIndices.includes(index)}
                                            onChange={() => handleSelectItem(index)}
                                        />
                                    </TableCell>
                                    <TableCell>{row.billingCombination.join(', ')}</TableCell>
                                    <TableCell>{row.billingNotation}</TableCell>
                                    <TableCell>{row.billingProperty}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}

            {showResults && (
                <>
                    <Box sx={{ textAlign: 'center', mb: '20px' }}>
                        <GeneralButton onClick={handleBackToSelection} text={'Back to Selection'} variant={"outlined"} sx={{
                            color: '#56C596',
                            borderColor: '#56C596 !important',
                            textTransform: 'none',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            width: '15.62vw',
                            '&:hover': {
                                backgroundColor: '#56C596',
                                color: 'white',
                                borderColor: '#56C596'
                            }
                        }} />
                    </Box>

                
                    <Box sx={{ mt: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                        {selectedItems.map((item, index) => (
                            <Card key={index} sx={{ width: '80%', maxWidth: '400px' }}>
                                <CardContent>
                                    {/* Billing Combination */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '10px' }}>
                                        <Typography variant="h6" sx={{ flexGrow: 1 }}>{item.billingCombination.join(', ')}</Typography>
                                        <Tooltip title="Copy Billing Combination">
                                            <IconButton onClick={() => copyToClipboard(item.billingCombination.join(', '))}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>

                                    {/* Billing Notation */}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ flexGrow: 1 }}>Notation: {item.billingNotation}</Typography>
                                        <Tooltip title="Copy Billing Notation">
                                            <IconButton onClick={() => copyToClipboard(item.billingNotation)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ flexGrow: 1 }}>Properties: {item.billingProperty}</Typography>
                                        <Tooltip title="Copy Billing Property">
                                            <IconButton onClick={() => copyToClipboard(item.billingProperty)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </>
            )}
        </>
    );
};
