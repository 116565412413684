import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

function IconWithHoverImage(props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={{ position: 'relative', display: 'inline-block', height: 'auto' }}>
      <IconButton
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <InfoIcon />
      </IconButton>
      {isHovered && (
        <img
          src={props.image}
          alt="Hover image"
          style={{
            position: 'absolute',
            width: '40vw', // Set width
            height: '40vh', // Set height
            top: '0%', // Align top edge with the top edge of the IconButton
            left: '100%', // Position image immediately to the right of the IconButton
            objectFit: 'cover', // Ensure the image covers the area
            transition: 'opacity 0.3s ease-in-out',
            zIndex: 1000, // Higher z-index to be on top
            visibility: isHovered ? 'visible' : 'hidden', // Controlled by state
            opacity: isHovered ? 1 : 0, // Controlled by state
          }}
        />
      )}
    </div>
  );
}

export default IconWithHoverImage;
