import React, { useState } from 'react';
import {  Button, Typography, Box } from '@mui/material';
import BillingTypeSelector from '../../Components/Calculator/BillingTypeSelector';
import BillingTypeSwitcher from '../../Components/Calculator/BillingTypeSwitcher';
import ConcessionEligibilitySection from '../../Components/Calculator/ConcessionEligibilitySection';
import ConcessionEligibilityRecordedSection from '../../Components/Calculator/ConcessionEligibilityRecordedSection';
import ConcessionItemsSection from '../../Components/Calculator/ConcessionItemsSection';
import ConcessionItemsRenderComponent from '../../Components/Calculator/ConcessionItemsRenderComponent';
import MixedBillingSelectItem from '../../Components/Calculator/MixedBillingSelectItems';
import './Calculator.css';
import MixedBillingResults from '../../Components/Calculator/MixedBillingResults';
import { SelectMixedBillingType } from '../../Components/Calculator/SelectMixedBillingType';
import CustomGapResults from '../../Components/Calculator/CustomGapResults';
import CustomPrivateResults from '../../Components/Calculator/CustomPrivateResults';

export const Calculator = () => {
    const [billingType, setBillingType] = useState('');
    const [billingTypeSelected, setBillingTypeSelected] = useState(false);
    const [values, setValues] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [error, setError] = useState('');
    const [calculationResults, setCalculationResults] = useState(null);
    //const [showItemForm, setShowItemForm] = useState(false);
    const [showCalculation, setShowCalculation] = useState(false); // New state to control rendering of calculation results.
    const [expandedItem, setExpandedItem] = useState(null);
    const [isConcessionEligible, setIsConcessionEligible] = useState(null);
    const [concessionRecorded, setConcessionRecorded] = useState(null);
    const [concessionValues, setConcessionValues] = useState([])
    const [showConcessionItemModal, setShowConcessionItemModal] = useState(false);
    const [seeBulkBillingIncentives, setSeeBulkBillingIncentives] = useState(false);
    const [concessionItemCombinationResults, setConcessionItemCombinationResults] = useState([]); 
    const [mixedBillingType, setMixedBillingType] = useState('');
    const [mixedBillingTypeSelected, setMixedBillingTypeSelected] = useState(false);
    const [showCustomGapResults, setShowCustomGapResults] = useState(false);
    const [customGapResults, setCustomGapResults] = useState(null);
    const [showCustomPrivateResults, setShowCustomPrivateResults] = useState(false);
    const [customPrivateResults, setCustomPrivateResults] = useState(null);

    const patientEligibilityGoBack = () => {

        setBillingType('bulk');
        setBillingTypeSelected(true)
        setIsConcessionEligible(true)
        setConcessionRecorded(null)
    }

    const handleExpandItem = (index) => {
        if (expandedItem === index) {
            setExpandedItem(null);
        } else {
            setExpandedItem(index);
        }
    };

    const handleReset = () => {

        setValues([]);
        setSelectedItems([]);
        setError('');
        setCalculationResults(null);
        //setShowItemForm(false);
        setShowCalculation(false); // Hide calculation results on reset.
        setShowCustomGapResults(false)
        setMixedBillingType('');
        setMixedBillingTypeSelected(false);
        setShowCustomPrivateResults(false)
        
    };

    const resetBillingType = () => {

        setShowConcessionItemModal(false)
        setSeeBulkBillingIncentives(false)
        setBillingType('');
        setBillingTypeSelected(false);
        setIsConcessionEligible(null);
        setConcessionRecorded(null)
        setConcessionValues([])
        setConcessionItemCombinationResults([])
        setMixedBillingType('');
        setMixedBillingTypeSelected(false);
        handleReset();
    };

    return (

        <Box className="container"  sx ={{backgroundColor: 'transparent', boxShadow: 'none', height: 'auto', marginTop: '5vh',  overflow: 'visible'}}>

            {/* Render the BillingTypeSelector component only if billingType is not selected */}
            {/* This condition ensures that the BillingTypeSelector component is only rendered when billingType is not yet selected */}
            {!billingTypeSelected && (
                /* BillingTypeSelector component */
                <BillingTypeSelector setBillingType={setBillingType} setBillingTypeSelected={setBillingTypeSelected} />
            )}

            {/* Conditionally render BillingTypeSwitcher component if billingType is selected */}
            {billingTypeSelected && (
                // Render BillingTypeSwitcher component to switch billing type
                <BillingTypeSwitcher resetBillingType={resetBillingType} />
            )}

            {billingType === 'bulk' && billingTypeSelected && !isConcessionEligible && isConcessionEligible !== false && (
                // Render the ConcessionEligibilitySection component only if the billing type is 'bulk',
                // billing type is selected, and the concession eligibility is not yet determined.
                <ConcessionEligibilitySection setIsConcessionEligible={setIsConcessionEligible} />
            )}

            {billingType === 'bulk' && billingTypeSelected && isConcessionEligible === false && (
                // Rendered when billing type is 'bulk', billing type is selected, and the patient is not eligible for bulk billing incentives
                <>
                    {/* Display message indicating patient is not eligible */}
                    <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>This Patient is not eligible for bulk billing incentives </Typography>                    
                    {/* Button to go back and reset billing type */}
                    <Button onClick={resetBillingType} sx={{
                            color: 'white', // Text and border color
                            borderColor: '#56C596 !important', // Explicit border color
                            textTransform: 'none', // Keeps text as provided
                            backgroundColor: '#56C596',
                            boxShadow: 'none',
                            width: '20.50vw', // Set width
                            height: '6.1vh', // Set height
                            '&:hover': {
                                backgroundColor: '#56C596', // Fills the button on hover
                                color: 'white', // Changes text color to white on hover
                                borderColor: '#56C596', // Ensures border color remains consistent on hover
                            }
                        }}>Finish</Button>
                </>
            )}

            {billingType === 'bulk' && billingTypeSelected && isConcessionEligible === true && !concessionRecorded && concessionRecorded !== false && (
                // Render the ConcessionEligibilityRecordedSection component if the following conditions are met:
                // 1. The billing type is 'bulk'
                // 2. The billing type has been selected
                // 3. The patient is eligible for concessions
                // 4. The concession eligibility is not yet recorded
                // 5. The concession eligibility has not been explicitly set to false

                <ConcessionEligibilityRecordedSection setConcessionRecorded={setConcessionRecorded} />
            )}


            {billingType === 'bulk' && billingTypeSelected && concessionRecorded === false && (
                // Render the following JSX if the following conditions are met:
                // 1. The billing type is 'bulk'
                // 2. The billing type has been selected
                // 3. The concession eligibility has been recorded as false
                <>
                    {/* Display a message prompting to update the patient's concession eligibility */}
                   <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}> Please make sure to update the patient's concession eligibility in Zedmed </Typography>
                    {/* Button to navigate back */}
                    <Button onClick={patientEligibilityGoBack} sx={{
                            color: 'white', // Text and border color
                            borderColor: '#56C596 !important', // Explicit border color
                            textTransform: 'none', // Keeps text as provided
                            backgroundColor: '#56C596',
                            boxShadow: 'none',
                            width: '20.50vw', // Set width
                            height: '6.1vh', // Set height
                            '&:hover': {
                                backgroundColor: '#56C596', // Fills the button on hover
                                color: 'white', // Changes text color to white on hover
                                borderColor: '#56C596', // Ensures border color remains consistent on hover
                            }
                        }}>Go Back</Button>
                </>
            )}

            {billingType === 'bulk' &&   // Check if billing type is 'bulk'
                billingTypeSelected &&      // Check if billing type is selected
                isConcessionEligible &&    // Check if the patient is eligible for concession
                concessionRecorded &&     // Check if concession eligibility is recorded
                !seeBulkBillingIncentives && // Check if bulk billing incentives are not yet seen
                (
                    // Render ConcessionItemsSection component
                    <ConcessionItemsSection
                        concessionValues={concessionValues} // Array of concession values
                        setShowConcessionItemModal={setShowConcessionItemModal} // Function to set modal visibility
                        setConcessionValues={setConcessionValues} // Function to set concession values
                        setSeeBulkBillingIncentives={setSeeBulkBillingIncentives} // Function to set bulk billing incentives visibility
                        showConcessionItemModal={showConcessionItemModal} // Boolean indicating if modal is visible
                        setConcessionItemCombinationResults = {setConcessionItemCombinationResults} // setter function for setting the result of the combination search
                    />
                )}


            {/* 
                Conditional rendering: if seeBulkBillingIncentives is true, render ConcessionItemsRenderComponent 
            with the concessionValues prop
            */}
            {seeBulkBillingIncentives && (
                <ConcessionItemsRenderComponent concessionValues={concessionValues} finishFunction={resetBillingType} concessionBillingCombinationValues={concessionItemCombinationResults} />
            )}


            {/* {billingType === 'mixed' && billingTypeSelected && (
                 <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black', marginBottom: '40px' }}>Add items for your split billing below</Typography>
            )} */}

            {/*
    Render MixedBillingSelectItem component only if the following conditions are met:
    - billingType is 'mixed'
    - billingTypeSelected is true
    - showCalculation is false
*/}

    {billingType === 'mixed' && billingTypeSelected && !showCalculation && !mixedBillingTypeSelected && (
        <SelectMixedBillingType setShowNext = {setMixedBillingTypeSelected} setCalculationType = {setMixedBillingType}/>
    )}

            {billingType === 'mixed' && billingTypeSelected && !showCalculation && mixedBillingTypeSelected && !showCustomGapResults && !showCustomPrivateResults && (
                <MixedBillingSelectItem
                    showCalculation={showCalculation}
                    handleReset={handleReset}
                    error={error}
                    values={values}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    setValues={setValues}
                    setCalculationResults={setCalculationResults}
                    setShowCalculation={setShowCalculation}
                    setError={setError}
                    mixedBillingType={mixedBillingType}
                    setShowCustomGapResults={setShowCustomGapResults}
                    setCustomGapResults ={setCustomGapResults}
                    setShowCustomPrivateResults = {setShowCustomPrivateResults}
                    setCustomPrivateResults = {setCustomPrivateResults}
                />
            )}

            {showCustomGapResults && (
                <CustomGapResults handleReset={handleReset} calculationResults={customGapResults}/>
            )}

            {showCustomPrivateResults && (
                <CustomPrivateResults handleReset={handleReset} calculationResults={customPrivateResults}/>
            )}

            {/* Render MixedBillingResults component if showCalculation is true and calculationResults is available */}
            {showCalculation && calculationResults && (
                <MixedBillingResults
                    calculationResults={calculationResults} // Pass calculationResults to the MixedBillingResults component
                    handleReset={handleReset} // Pass handleReset function to the MixedBillingResults component
                    handleExpandItem={handleExpandItem} // Pass handleExpandItem function to the MixedBillingResults component
                    expandedItem={expandedItem} // Pass expandedItem state to the MixedBillingResults component
                    selectedItems={selectedItems}
                />
            )}

        </Box>
    );
};

export default Calculator;
