export const sendPasswordResetEmail = async (email) => {
    try {
      const response = await fetch('/api/user/send-reset-password-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      // Handle success
      console.log("Success:", data.message);
      // You might want to update the UI to reflect the successful request here.

    } catch (error) {

      console.error("Error during fetch:", error.message);
      // Handle errors, such as displaying a notification to the user
      
    }
  }
  