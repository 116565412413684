export const checkForAnotherAdminAPI = async () => {
    try {
      const response = await fetch('api/admin/check-for-admin', {
        method: 'GET', // Assuming the route only needs a GET request
        credentials: 'include', // Ensures cookies are included with the request for session-based auth
        headers: {
          'Content-Type': 'application/json',
          // No need for 'Authorization' header if using session-based authentication
        },
      });
  
      if (!response.ok) {
        throw new Error(`Server returned ${response.status}: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // Adjusted to check if there's another admin based on the response
      if (data.anotherAdmin) {
        console.log('There is another admin present.');
        // Handle logic for when there is another admin
        return true; 
      } else {
        console.log('No other admin present.');
        // Handle logic for when no other admin is present
        return false;
      }
    } catch (error) {
      console.error('Error checking for another admin:', error);
      // Handle errors or exceptions here
    }
  };
  
 
  