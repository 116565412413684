import React, { useState } from 'react';
import {
    Typography,
    Box,
    IconButton,
    Collapse,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GeneralButton from '../General/Button';

const MixedBillingResults = ({ calculationResults, handleReset, selectedItems }) => {
    const [expandedItems, setExpandedItems] = useState({});
    const [instructionExpanded, setInstructionExpanded] = useState(false);

    const handleExpandClick = (index) => {
        setExpandedItems((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const handleInstructionExpandClick = () => {
        setInstructionExpanded(!instructionExpanded);
    };

    const handleCopy = (text) => {
        const formattedText = text.replace(/\n/g, ' ').replace(/-/g, '');
        navigator.clipboard.writeText(formattedText);
    };

    const formatCurrency = (value) => `$${Number(value).toFixed(2)}`;

    const generateBillingInstruction = () => {
        const itemsDetails = calculationResults.values.map(value => 
            `\n - Item ${value.billingNumber} with a value of ${formatCurrency(value.OPS)}`
        ).join(', ');

        return `Please charge the patient ${formatCurrency(calculationResults.totalCost)}.\n
Advise them that they will receive a rebate of ${formatCurrency(calculationResults.totalRebate)}.\n
They will be out of pocket/have a gap of ${formatCurrency(calculationResults.totalCost - calculationResults.totalRebate)}.\n
Please split the gap across: ${itemsDetails}.`;
    };

    const billingInstruction = generateBillingInstruction();

    return (
        <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: '1.8rem', mb: 1, color: 'black' }}>
                Results for Split Billing
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', backgroundColor: 'white', m: 2, borderRadius: '10px', textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>Total Cost: {formatCurrency(calculationResults.totalCost)}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>Total MBS Rebate: {formatCurrency(calculationResults.totalRebate)}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>Billing Split: {formatCurrency(calculationResults.outOfPocketSplit)}</Typography>

                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>
                        Patient out of pocket: {formatCurrency(calculationResults.totalCost - calculationResults.totalRebate)}
                    </Typography>
                </Box>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {calculationResults.values.map((value, index) => (
                        <li key={index} style={{ margin: '5px 0' }}>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="body1" sx={{ mb: 0.5 }}>
                                    Calculation Breakdown for Item Number: {value.billingNumber}{' '}
                                    <IconButton
                                        onClick={() => handleExpandClick(index)}
                                        aria-expanded={expandedItems[index]}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Collapse in={expandedItems[index]} timeout="auto" unmountOnExit>
                                <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', backgroundColor: 'white', mb: 6, borderRadius: '10px', textAlign: 'left' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', m: 2 }}>
                                        Out of Pocket Split ({formatCurrency(value.OPS)}) + MBS Rebate ({formatCurrency(value['mbsRebate'])}) = {formatCurrency(value['mbsRebate'] + value.OPS)}
                                    </Typography>
                                </Box>
                            </Collapse>
                        </li>
                    ))}
                </ul>
                <Box sx={{ textAlign: 'left', mt: 2 }}>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                        Billing Instruction{' '}
                        <IconButton
                            onClick={handleInstructionExpandClick}
                            aria-expanded={instructionExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Collapse in={instructionExpanded} timeout="auto" unmountOnExit>
                    <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', backgroundColor: 'white', mb: 6, borderRadius: '10px', textAlign: 'left', p: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.3rem', mb: 1, whiteSpace: 'pre-line' }}>
                            {billingInstruction}
                            <Tooltip title="Copy Billing Instruction">
                                <IconButton onClick={() => handleCopy(billingInstruction)} aria-label="copy to clipboard">
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <GeneralButton onClick={handleReset} text={'Finish'} sx={{
                backgroundColor: "#56C596",
                borderColor: '#56C596 !important',
                textTransform: 'none', // Keeps text as provided
                width: '6.50vw',
            }}></GeneralButton>
        </Box>
    );
};

export default MixedBillingResults;
