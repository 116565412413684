// src/services/userService.js



// Function to fetch users
export const adminGetUsers = async () => {
  try {
    const response = await fetch('/api/admin/getUser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Include other headers as needed, for example, Authorization for JWT
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const users = await response.json();
    return users;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error; // Re-throw the error so it can be caught by the caller
  }
};
