import { useState } from 'react';

const useSnackbar = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');

    const handleSetSnackBar = (message, severity, open = true) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(open);
    };

    const handleCloseSnackBar = () => {
        setSnackbarMessage('');
        setSnackbarSeverity('');
        setSnackbarOpen(false);
    };

    return {
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        handleSetSnackBar,
        handleCloseSnackBar,
    };
};

export default useSnackbar;
