

export const retrieveBillingNotationCombination = async (arrayOfItemNumbers) => {
    try {
      const response = await fetch('/api/concession-billing-items/get-notation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items: arrayOfItemNumbers }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      // Handle success
      console.log("Success: ", data.message);
      console.log(data.results)
      return data.results;

    } catch (error) {

      console.error("Error during fetch:", error.message);
      // Handle errors, such as displaying a notification to the user
      
    }
  }
  