import { Box, InputAdornment, TextField, Typography,IconButton, Button } from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import React, { useState, useEffect } from 'react';
import { logoutUser } from "../services/logoutUser";
 

export const Login = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
      };

      const [formState, setFormState] = useState({
        username: '',
        password: '',
        token: '',
      });

      const location = useLocation();
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      


      const [showPassword, setShowPassword] = useState(false);
      
     
      const { login, setIsAdmin, logout, isLoggedIn } = useAuth();

      const navigate = useNavigate();
      const [loginError, setLoginError] = useState();
    
      const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
      };
    
      // Function to clear input fields
      const clearInputFields = () => {
        setFormState({ username: '', password: '' });
      };


      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError(''); // Reset login error message
    
        try {
          const response = await fetch('/api/user/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formState),
          });
    
          if (response.ok) {
            const data = await response.json();
    
            setIsAdmin(data.isAdmin);
            login(); // Set isLoggedIn to true
       
            navigate('/home'); // Redirect to the home page
          } else {
            const errorData = await response.json(); // Assuming the server responds with JSON
            setLoginError(errorData.message); // Set the error message from response
            console.error("HTTP error:", response.status, response.statusText, errorData.message);
          }
        } catch (error) {
          console.error("Network error:", error.message);
          setLoginError('Network error, please try again.'); // Set a general error message
        }
      };
      
      useEffect(() => {
        if (!token) {
            console.log("No Token")
        }

        if (token) {
            setFormState({...formState, token: token})
            
        }
        // if the user is logged in and there is a token to verify then 
        // log them out so that the verification can happen.
        if (isLoggedIn && token) {
            logoutUser(logout)
            
            
        
        } else if (isLoggedIn) {
            navigate('/home')
        }

      }, [token, isLoggedIn])
      

      const isFormFilled = formState.username !== '' && formState.password !== '';


    return (
        <Box component="form" sx={style} onSubmit={handleSubmit}>
        <Typography
          id="login-modal-title"
          component="h2"
          sx={{
            fontFamily: 'Manrope, sans-serif',
            fontSize: 25
          }}
        >
          Welcome back
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>Email address</Typography>

        <TextField

          value={formState.username}
          onChange={(e) => setFormState({ ...formState, username: e.target.value })}
          fullWidth
          margin="normal"
          placeholder="hello@domain.com"
          sx={{

            borderRadius: '10px',

            backgroundColor: '#F1FAF7',


          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>Password</Typography>
        <TextField

          type={showPassword ? 'text' : 'password'}
          value={formState.password}
          onChange={(e) => setFormState({ ...formState, password: e.target.value })}
          fullWidth
          margin="normal"
          placeholder="Password" // Set the placeholder text
          sx={{

            borderRadius: '10px',

            backgroundColor: '#F1FAF7',


          }}


          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />


{loginError && (
      <Box color="error.main" mb={2} mt={2} display="flex" alignItems="center">
        <WarningOutlinedIcon sx={{ marginRight: '15px' }} />
        <Typography variant="body1" color="error.main">
          Incorrect Details
        </Typography>
        
      </Box>
    )}


        <Button
          type="submit"
          variant="contained"
          
          fullWidth
          disabled={!isFormFilled} // Disable the button if form is not filled
          sx ={{
          backgroundColor:"#56C596",
          borderColor:'#56C596 !important',
          textTransform: 'none' // Keeps text as provided
          }}
        >
          Login
        </Button>


        <Typography variant="body1" align="center" style={{ paddingTop: '20px' }}>
          <Link to="/send-reset-password" style={{ color: '#56C596', fontFamily: 'Manrope, sans-serif', fontSize: '16px', textDecoration: 'none' }}>
            Forgot your password?
          </Link>
        </Typography>
        </Box>
    )

}