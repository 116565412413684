import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { sendPasswordResetEmail } from '../services/sendPasswordResetEmailApi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';


export const SendResetPassword = () => {
    const [email, setEmail] = useState('');
    const [messageShown, setMessageShown] = useState(false);
    const {isLoggedIn} = useAuth(); 
    const navigate = useNavigate();


    useEffect(() => {
        if (isLoggedIn) {
            navigate('/'); // Redirect to homepage or dashboard if logged in
        }
    }, [isLoggedIn, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessageShown(true);

        // Here, insert the logic to actually attempt to send the password reset email.
        console.log("Request to send password reset email for:", email);

        // Simulate an async operation
        try {
            console.log("Reset password email sent (if account exists).");
            console.log("Request to send password reset email for:", email);
            await sendPasswordResetEmail(email);

            setTimeout(() => {
                navigate('/');    
            }, (5000));

                 
            
        } catch (error) {
            console.error('Failed to send reset password email:', error);
        }
       
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Typography variant="h5" gutterBottom>
                Send Reset Password Email
            </Typography>
            {!messageShown && (
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="Email Address"
                        type="email"
                        id="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!email} // Button is disabled if email is empty
                    >
                        Reset Password
                    </Button>
                </Box>
            )}
            {messageShown && (
                <Alert severity="info" sx={{ mt: 3 }}>
                    If this account exists, a password reset link will be sent to it.
                </Alert>
            )}
        </Box>
    );
};
