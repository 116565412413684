// AdminProtectedRoute.js
import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';

const AdminProtectedRoute = () => {
    const { isLoggedIn, isLoading, isAdmin } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && (!isLoggedIn || !isAdmin)) {
            // Not logged in or not admin, redirect accordingly
            navigate('/'); // Redirect to a general "unauthorized" or home page
        }
    }, [isLoggedIn, isLoading, isAdmin, navigate]);

    if (isLoading) {
        return <div>Loading...</div>; // Customize this loading indicator as needed
    }

    // User is logged in and is admin, render the Outlet for nested routes
    return <Outlet />;
};

export default AdminProtectedRoute;
